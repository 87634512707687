'use client';

import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import CoinBundleGrid from './coin-bundle-grid';
import ArrowIcon from 'icons/arrow';
import { Button } from 'components/ui/button';
import { type DialogProps } from '@radix-ui/react-dialog';
import AffiliateCodeForm from 'components/payments/affiliate-code-form';
import { useMemo, useState } from 'react';
import Image from 'next/image';
import Typography from 'components/ui/typography';
import formatCurrency from 'utils/format/currency';
import crypto from 'public/crypto.png';
import InfoTooltip from 'components/info-tooltip';
import { type Database } from 'schema.gen';
import GiftCodeForm from 'components/payments/gift-code-form';
import Script from 'next/script';
import { onCryptoChillScriptLoad } from 'lib/cryptochill';
import ClientFormattedDateTime from 'components/client-formatted-date-time';
import Coins from 'components/coins';
import StripeCheckout from './stripe-checkout';

export default function DepositDialog({
  selfExclusion,
  affiliateCodeRedemption: redeemedAffiliateCode,
  coinBundles,
  user,
  children,
  ...props
}: DialogProps & {
  affiliateCodeRedemption: {
    affiliate_code_id: string;
    id: string;
    locked_until: string;
    redeemed_at: string;
    redeemer_id: string;
    affiliate_codes: {
      depositor_bonus_percentage: number;
      code: string;
    } | null;
  } | null;
  coinBundles: Database['public']['Tables']['coin_bundles']['Row'][];
  user: {
    id: string;
    email: string;
    name: string;
  };
  selfExclusion: {
    expires_at: string;
  } | null;
}) {
  const [open, setOpen] = useState(false);
  const [selectedBundleId, setSelectedBundleId] = useState<string | null>(null);

  const selectedBundle = useMemo(() => {
    return coinBundles?.find((bundle) => bundle.id === selectedBundleId);
  }, [coinBundles, selectedBundleId]);

  const affiliateBonus = useMemo(() => {
    if (redeemedAffiliateCode === null || selectedBundle === undefined) {
      return null;
    }

    return Math.floor(
      (selectedBundle.coins *
        redeemedAffiliateCode.affiliate_codes!.depositor_bonus_percentage) /
        100,
    );
  }, [redeemedAffiliateCode, selectedBundle]);

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    props.onOpenChange?.(open);
  };

  return (
    <>
      <Dialog {...props} open={open} onOpenChange={onOpenChange}>
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent
          className={selectedBundle === undefined ? 'max-w-screen-lg' : ''}
        >
          <Script
            id="cryptochill"
            src="https://static.cryptochill.com/static/js/sdk2.js"
            onLoad={onCryptoChillScriptLoad}
          />
          {selfExclusion !== null && (
            <div className="flex items-center justify-between rounded-lg bg-destructive p-4">
              <Typography variant="p">
                You are currently self-excluded until{' '}
                <ClientFormattedDateTime
                  createdAt={new Date(selfExclusion.expires_at)}
                />
              </Typography>
            </div>
          )}
          <Header
            showBackButton={selectedBundle !== undefined}
            onBackClick={() => setSelectedBundleId(null)}
          />
          <GiftCodeForm onSuccess={() => onOpenChange(false)} />

          <DialogBody>
            {selectedBundle === undefined && (
              <CoinBundleGrid
                onSelect={setSelectedBundleId}
                coinBundles={coinBundles}
                disabled={selfExclusion !== null}
              />
            )}
            {selectedBundle !== undefined && (
              <Summary
                bundleId={selectedBundle.id}
                costInCents={selectedBundle.cost_in_cents}
                coins={selectedBundle.coins}
                affiliateBonus={affiliateBonus}
                user={user}
                closeModal={() => {
                  setSelectedBundleId(null);
                  onOpenChange(false);
                }}
              />
            )}
          </DialogBody>

          <DialogFooter>
            {redeemedAffiliateCode === null && <AffiliateCodeForm />}
            {redeemedAffiliateCode !== null && (
              <Typography variant="p" affects="muted">
                🔥 You&apos;re currently using code{' '}
                <span className="font-bold">
                  {redeemedAffiliateCode.affiliate_codes!.code}
                </span>{' '}
                for a{' '}
                <span className="text-gold-shimmer font-bold">
                  {
                    redeemedAffiliateCode.affiliate_codes!
                      .depositor_bonus_percentage
                  }
                  % deposit bonus
                </span>{' '}
                until{' '}
                <ClientFormattedDateTime
                  createdAt={new Date(redeemedAffiliateCode.locked_until)}
                />
                !
              </Typography>
            )}
          </DialogFooter>

          <DialogDescription className="sr-only">
            Refill your coin balance with a debit/credit card or cryptocurrency.
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Header({
  showBackButton,
  onBackClick,
}: {
  showBackButton?: boolean;
  onBackClick?: () => void;
}) {
  return (
    <DialogHeader>
      <div className="grid w-full grid-cols-[1fr,auto,1fr] items-center gap-4">
        {showBackButton ? (
          <button onClick={onBackClick}>
            <ArrowIcon className="text-gold rotate-90 scale-[200%]" />
          </button>
        ) : (
          <span></span>
        )}
        <DialogTitle>Coin Refill</DialogTitle>
      </div>
    </DialogHeader>
  );
}

function Summary({
  bundleId,
  costInCents,
  coins,
  affiliateBonus,
  user,
  closeModal,
}: {
  bundleId: string;
  costInCents: number;
  coins: number;
  affiliateBonus: number | null;
  user: {
    id: string;
    email: string;
    name: string;
  };
  closeModal: () => void;
}) {
  return (
    <>
      <div className="flex flex-col gap-y-4">
        <Typography variant="p" affects="large">
          Summary
        </Typography>

        <div className="flex flex-col gap-y-2">
          <div className="bg-frost flex items-center justify-between rounded-lg p-4">
            <Typography variant="p">Price</Typography>

            <Typography variant="p">{formatCurrency(costInCents)}</Typography>
          </div>

          <div className="bg-frost flex items-center justify-between rounded-lg p-4">
            <Typography variant="p">Coins</Typography>

            <span className="bg-frost rounded-lg px-2 py-1">
              <Coins value={coins} />
            </span>
          </div>
          {affiliateBonus !== null && (
            <div className="bg-frost flex items-center justify-between rounded-lg p-4">
              <Typography variant="p" className="text-gold-shimmer font-bold">
                + Affiliate Bonus
              </Typography>

              <span className="bg-frost rounded-lg px-2 py-1">
                <Coins value={affiliateBonus} />
              </span>
            </div>
          )}
        </div>
        <div className="h-px w-full bg-white/10" />

        <div className="grid grid-cols-1 gap-2">
          <div className="bg-frost flex items-center justify-between rounded-lg p-4">
            <Typography variant="p" affects="large">
              Debit/Credit Card
            </Typography>

            {/* <Link
              href={`/deposit/coinflow?bundle=${bundleId}`}
              onClick={closeModal}
            >
              <Button size="sm">
                <Image
                  src={coinflow}
                  alt="Coinflow"
                  className="h-full w-min p-2"
                />
              </Button>
            </Link> */}
            <StripeCheckout
              bundleId={bundleId}
              onCheckoutSuccess={closeModal}
            />
          </div>

          <div className="bg-frost flex items-center justify-between rounded-lg p-4">
            <span className="flex items-center gap-2">
              <Typography variant="p" affects="large">
                Cryptocurrency
              </Typography>

              <CryptoTooltip />
            </span>
            <Button
              size="sm"
              variant="ghost"
              className="cryptochill-button hover:bg-transparent"
              onClick={() => {
                closeModal();
                window.CryptoChill.open({
                  amount: costInCents / 100,
                  currency: 'USD',
                  onSuccess: () => {
                    window.CryptoChill.destroy();
                    closeModal();
                  },
                  product: `Pullbox Coins`,
                  showPaymentURL: true,
                  passthrough: JSON.stringify({
                    userId: user.id,
                    email: user.email,
                    name: user.name,
                    currencyBundleId: bundleId,
                  }),
                });
              }}
            >
              <Image
                src={crypto}
                alt="Various cryptocurrencies"
                className="h-full w-min"
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

function CryptoTooltip() {
  return (
    <InfoTooltip>
      <Typography variant="p">
        Pullbox.gg accepts cryptocurrency as a payment method. We accept Bitcoin
        (BTC), Ethereum (ETH), or Litecoin (LTC) for coin purchases.
      </Typography>

      <Typography variant="p">
        If you are new to crypto and would like to acquire it, explore popular
        exchanges like Coinbase, Gemini, or Crypto.com, where you can purchase
        and transfer all different types of cryptocurrency. You can then use
        these exchanges to create a crypto wallet, and deposit that
        cryptocurrency on Pullbox.gg!
      </Typography>
    </InfoTooltip>
  );
}
