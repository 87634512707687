'use client';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import Coins from 'components/coins';
import Observe from 'components/events/observe';
import getUserBalance from 'queries/get-user-balance';
import { useDebouncedCallback } from '@mantine/hooks';

export default function HeaderLiveBalance({
  initialBalance,
  userId,
}: {
  initialBalance: number;
  userId: string;
}) {
  const queryClient = useQueryClient();

  const { data: balance } = useQuery({
    queryKey: ['balance', userId],
    initialData: initialBalance,
    refetchOnMount: false,
    staleTime: Infinity,
    queryFn: async () => {
      const createClient = await import('utils/supabase/client').then(
        (mod) => mod.createClient,
      );
      const supabase = createClient({ fetchOptions: { cache: 'no-store' } });

      const balance = await getUserBalance(supabase, userId);
      return balance;
    },
  });

  const onTransactionCreated = useDebouncedCallback(() => {
    void queryClient.invalidateQueries({ queryKey: ['balance', userId] });
  }, 1000);

  return (
    <>
      <Coins value={balance} animated />
      <Observe onTransactionCreated={onTransactionCreated} />
    </>
  );
}
