export default function GearIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        d="M14.844 6.519a.501.501 0 0 0-.25-.331l-1.487-.825a4.676 4.676 0 0 0-.263-.469l.025-1.7a.52.52 0 0 0-.162-.381 7 7 0 0 0-2.57-1.481.506.506 0 0 0-.412.05l-1.456.875a7.707 7.707 0 0 0-.538 0l-1.456-.875a.506.506 0 0 0-.412-.05 6.944 6.944 0 0 0-2.569 1.487.494.494 0 0 0-.163.375l.032 1.7c-.1.15-.188.306-.275.469l-1.482.825a.481.481 0 0 0-.25.331 6.963 6.963 0 0 0 0 2.963.5.5 0 0 0 .25.33l1.488.826c.08.162.17.318.269.469l-.032 1.7a.519.519 0 0 0 .163.38 7.056 7.056 0 0 0 2.569 1.482.505.505 0 0 0 .412-.05l1.456-.875h.538l1.462.875c.076.05.166.076.257.075a.62.62 0 0 0 .15-.025 6.943 6.943 0 0 0 2.569-1.488.493.493 0 0 0 .162-.374l-.025-1.7c.094-.15.181-.307.269-.47l1.487-.824a.493.493 0 0 0 .244-.331 6.963 6.963 0 0 0 0-2.963ZM10.75 8a2.75 2.75 0 1 1-5.5 0 2.75 2.75 0 0 1 5.5 0Z"
      />
    </svg>
  );
}
