'use client';

import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react';
import { LEFT_SIDEBAR_WIDTH, useLayout } from './layout-context';
import { type ReactNode } from 'react';
import { cn } from 'utils/shadcn';

export default function LeftSidebar({ children }: { children: ReactNode }) {
  const { isLeftSidebarOpen, setLeftSidebarOpen, isLeftSidebarOpenable } =
    useLayout();

  return (
    <>
      <div
        className={cn(
          `pointer-events-auto absolute isolate z-10 flex h-full transition-transform duration-200 ease-out`,
          {
            '-translate-x-full': !isLeftSidebarOpen,
            'translate-x-0': isLeftSidebarOpen,
          },
        )}
        style={{
          transitionDuration: '200ms',
          transitionTimingFunction: 'ease-out',
        }}
      >
        <section
          className="relative z-40 flex h-full flex-col overflow-hidden bg-primary-foreground"
          style={{ width: LEFT_SIDEBAR_WIDTH }}
        >
          {children}
        </section>
      </div>

      <button
        className={`pointer-events-auto fixed bottom-4 z-20 flex h-10 items-center rounded-br-sm rounded-tr-sm bg-primary-foreground transition-transform duration-200 ease-out`}
        style={{
          transform: isLeftSidebarOpen
            ? `translateX(${LEFT_SIDEBAR_WIDTH})`
            : !isLeftSidebarOpenable
              ? 'translateX(-2.5rem)'
              : 'translateX(0)',
          transitionDuration: '200ms',
          transitionTimingFunction: 'ease-out',
        }}
        onClick={() => setLeftSidebarOpen(!isLeftSidebarOpen)}
      >
        <div className="flex h-full w-10 items-center justify-center bg-transparent">
          {isLeftSidebarOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        </div>
      </button>
    </>
  );
}
