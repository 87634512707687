import DiscordSignup from './discord-signup';
import GoogleSignup from './google-signup';
import TwitchSignup from './twitch-signup';

export default function OAuthButtons() {
  return (
    <span className="grid w-full grid-cols-3 items-center justify-center space-x-2">
      <GoogleSignup />
      <DiscordSignup />
      <TwitchSignup />
    </span>
  );
}
