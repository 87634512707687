export const getXpRequiredForLevel = (level: number) => {
  return Math.floor(
    0.25 *
      Array.from({ length: level - 1 }, (_, i) => i + 1).reduce((xp, x) => {
        return xp + Math.floor(x + 300 * Math.pow(2, x / 7));
      }, 0),
  );
};

export const getLevelFromXp = (xp: number) => {
  for (let level = 1, levelXp = 0; level <= 100; level++) {
    levelXp = getXpRequiredForLevel(level);

    if (levelXp > xp) {
      return level - 1;
    }
  }

  return 100;
};

export const getCurrentLevelProgress = (xp: number) => {
  const level = getLevelFromXp(xp);

  const xpRequiredForCurrentLevel = getXpRequiredForLevel(level);
  const xpRequiredForNextLevel = getXpRequiredForLevel(level + 1);

  if (level === 100) {
    return 1;
  }

  return (
    (xp - xpRequiredForCurrentLevel) /
    (xpRequiredForNextLevel - xpRequiredForCurrentLevel)
  );
};
