'use client';

import * as React from 'react';

import {
  Close,
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
} from '@radix-ui/react-dialog';

import CrossIcon from 'icons/cross';
import Divider from './divider';
import Typography from './typography';
import { cn } from 'utils/shadcn';

const Dialog = Root;

const DialogTrigger = Trigger;

const DialogPortal = Portal;

const DialogClose = Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof Overlay>,
  React.ComponentPropsWithoutRef<typeof Overlay>
>(({ className, ...props }, ref) => (
  <Overlay
    ref={ref as React.Ref<HTMLDivElement>}
    className={cn(
      'fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay>
      <Content
        ref={ref as React.Ref<HTMLDivElement>}
        className={cn(
          'relative z-50 flex max-h-[calc(100dvh-2rem)] w-full max-w-modal flex-col gap-4 overflow-y-auto rounded-xl bg-dialog p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 sm:rounded-lg md:my-8',
          className,
        )}
        onInteractOutside={(e) => {
          const { originalEvent } = e.detail;
          if (
            originalEvent.target instanceof Element &&
            originalEvent.target.closest('.group.toast')
          ) {
            e.preventDefault();
          }
        }}
        {...props}
      >
        {children}
        <Close className="absolute right-4 top-4 z-50 rounded-sm opacity-70 transition-opacity data-[state=open]:bg-accent data-[state=open]:text-muted-foreground hover:opacity-100 focus:outline-none disabled:pointer-events-none">
          <CrossIcon className="size-5 text-gold" />
          <span className="sr-only">Close</span>
        </Close>
      </Content>
    </DialogOverlay>
  </DialogPortal>
));
DialogContent.displayName = Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="space-y-2">
    <div
      className={cn(
        'flex flex-col space-y-1.5 text-center sm:text-left',
        className,
      )}
      {...props}
    ></div>
    <Divider />
  </div>
);
DialogHeader.displayName = 'DialogHeader';

const DialogBody = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const [height, setHeight] = React.useState<number>(0);
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!divRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHeight(entry.target.scrollHeight);
      }
    });

    setHeight(divRef.current.scrollHeight);
    resizeObserver.observe(divRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className="overflow-y-auto scrollbar-thin" style={{ height }}>
      <div ref={divRef} {...props} />
    </div>
  );
};
DialogBody.displayName = 'DialogBody';

const DialogFooter = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse border-t border-gold pt-4 sm:flex-row sm:justify-end sm:space-x-2',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof Title>,
  React.ComponentPropsWithoutRef<typeof Title>
>(({ children, ...props }, ref) => (
  <Title ref={ref as React.Ref<HTMLDivElement>} {...props} asChild>
    <Typography
      variant="h1"
      className="text-gold-shimmer text-center text-2xl md:text-4xl"
    >
      {children}
    </Typography>
  </Title>
));
DialogTitle.displayName = Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof Description>,
  React.ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description
    ref={ref as React.Ref<HTMLDivElement>}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
DialogDescription.displayName = Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogBody,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
