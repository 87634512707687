'use client';

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { useCallback, useRef } from 'react';

import { Button } from 'components/ui/button';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Input } from 'components/ui/input';
import UsernameInput from './username-input';
import { env } from 'env';
import { generateUsername } from 'friendly-username-generator';
import { signup } from 'actions/auth';
import { signupSchema } from './validation';
import { toast } from 'components/ui/sonner';
import { useHookFormAction } from '@next-safe-action/adapter-react-hook-form/hooks';
import { zodResolver } from '@hookform/resolvers/zod';

export default function SignupForm({ onSuccess }: { onSuccess: () => void }) {
  const { form, handleSubmitWithAction } = useHookFormAction(
    signup,
    zodResolver(signupSchema),
    {
      formProps: {
        mode: 'onChange',
        defaultValues: {
          email: '',
          password: '',
          username: generateUsername({ useHyphen: false }),
        },
      },
      actionProps: {
        onExecute: () => {
          resetCaptcha();
        },
        onError: ({ error }) => {
          console.log('onError', error);
          if (error.serverError) {
            toast.error(error.serverError.message);
          }
        },
        onSuccess: async ({ data }) => {
          if (data?.user) {
            if (!data.user.email_confirmed_at) {
              toast.success(
                'Welcome to Pullbox! Please check your email for a confirmation link.',
              );
            } else {
              toast.success('Welcome to Pullbox!');

              const createClient = await import('utils/supabase/client').then(
                (mod) => mod.createClient,
              );

              // causes realtime websocket connections to use the current session
              await createClient().auth.refreshSession();
            }

            onSuccess();
          }
        },
      },
    },
  );

  const captcha = useRef<HCaptcha>(null);
  const resetCaptcha = useCallback(() => {
    captcha.current?.resetCaptcha();
    form.setValue('captchaToken', '');
  }, [form]);

  const onRandomize = async (username: string) => {
    form.setValue('username', username);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmitWithAction}
        className="flex flex-col space-y-4"
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <UsernameInput {...field} onRandomize={onRandomize} />
              </FormControl>
              <FormMessage />
              <FormDescription>
                This is the name people will know you by on Pullbox. You can
                always change it later.
              </FormDescription>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input {...field} placeholder="Email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input type="password" placeholder="Password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {env.NEXT_PUBLIC_CAPTCHA_ENABLED && (
          <FormField
            control={form.control}
            name="captchaToken"
            render={({ field }) => (
              <FormItem>
                <HCaptcha
                  ref={captcha}
                  sitekey={env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
                  onVerify={(token) => field.onChange(token)}
                  onExpire={resetCaptcha}
                  onChalExpired={resetCaptcha}
                  theme="dark"
                  loadAsync
                />
              </FormItem>
            )}
          />
        )}

        {form.formState.errors.root && (
          <FormMessage>{form.formState.errors.root.message}</FormMessage>
        )}

        <Button
          className="w-full"
          type="submit"
          variant="action"
          disabled={!form.formState.isValid}
          isProcessing={form.formState.isSubmitting}
          size="lg"
        >
          Sign up
        </Button>
      </form>
    </Form>
  );
}
