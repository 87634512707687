export default function CrossIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="12"
        y="14"
        width="3"
        height="3"
        transform="rotate(-45 12 14)"
        fill="currentColor"
      />
      <rect
        x="8.41421"
        y="14"
        width="8"
        height="8"
        transform="rotate(-45 8.41421 14)"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M2 26L9 19" stroke="currentColor" strokeWidth="2" />
      <path d="M26 26L19 19" stroke="currentColor" strokeWidth="2" />
      <path d="M2 2L9 9" stroke="currentColor" strokeWidth="2" />
      <path d="M26 2L19 9" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}
