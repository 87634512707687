'use client';

import type { Database } from 'schema.gen';
import type { InventoryItem } from 'queries/get-user-inventory-items';
import type { SupabaseClient } from '@supabase/supabase-js';
import { useCallback } from 'react';
import { useEvents } from 'components/events/context';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';

export default function RealtimeInventoryEmitter({
  userId,
}: {
  userId: string;
}) {
  const { emitter } = useEvents();

  const createChannel = useCallback(
    async (supabase: SupabaseClient<Database>) => {
      return supabase.channel('realtime_inventory').on<InventoryItem>(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'inventory_items',
          filter: `owner=eq.${userId}`,
        },
        (payload) => {
          emitter.emit('inventory-changed', {
            eventType: payload.eventType,
            old: payload.old,
            new: payload.new,
          });
        },
      );
    },
    [emitter, userId],
  );

  useReconnectOnTabChange(createChannel);

  return null;
}
