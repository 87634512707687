export default function DoubleDiamondIcon(
  props: React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth={2}
        d="M1.414 7 7 1.414 12.586 7 7 12.586 1.414 7Z"
      />
      <path fill="currentColor" d="m5 7 2-2 2 2-2 2-2-2Z" />
    </svg>
  );
}
