'use client';

import FullLogo from 'components/full-logo';
import IconLogo from 'components/icon-logo';
import Link from 'next/link';
import { useCategory } from 'app/(main)/category-context';

export default function HeaderLogo() {
  const { category } = useCategory();

  return (
    <Link href={`/${category}`}>
      <IconLogo className="lg:hidden" />
      <FullLogo className="hidden lg:block" />
    </Link>
  );
}
