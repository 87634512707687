import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';

import InfoIcon from 'icons/info';
import Typography from './ui/typography';
import { cn } from 'utils/shadcn';

export default function InfoTooltip({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="bg-frost group rounded-sm p-1 transition-colors hover:bg-gold">
          <InfoIcon
            width={12}
            height={12}
            className="text-gold transition-colors group-hover:text-white"
          />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className={cn('w-96 space-y-4 bg-gold p-4 text-white', className)}
        side="top"
        sideOffset={8}
      >
        <Typography variant="span" affects="small">
          {children}
        </Typography>
      </PopoverContent>
    </Popover>
  );
}
