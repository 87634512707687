'use client';

import { Button, type ButtonProps } from 'components/ui/button';
import { useCallback, useRef, useState, useEffect } from 'react';
import { useFormState, useFormStatus } from 'react-dom';

import { EMPTY_FORM_STATE } from 'utils/forms';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { env } from 'env';
import { joinCoinDrop } from 'actions/coin-drop';
import { toast } from 'components/ui/sonner';
import { useFormToastMessage } from 'hooks/use-form-toast-message';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';
import { cn } from 'utils/shadcn';
import Typography from 'components/ui/typography';
import { CheckIcon } from '@radix-ui/react-icons';
import Divider from 'components/ui/divider';
import { Progress } from 'components/ui/progress';
import Link from 'next/link';
import { useAuthDialog } from 'components/auth/auth-dialog-context';
import Coins from 'components/coins';
import type { SupabaseClient } from '@supabase/supabase-js';
import type { Database } from 'schema.gen';
import InfoTooltip from 'components/info-tooltip';

export default function RealtimeCoinDrop({
  userId,
  hasVerifiedPhone,
  serverValue,
  serverIsJoined,
  serverJoinableAt,
  serverStartsAt,
  serverEndsAt,
  serverTimeRemainingPercentage,
}: {
  userId?: string;
  hasVerifiedPhone: boolean;
  serverValue: number;
  serverIsJoined: boolean;
  serverJoinableAt: string;
  serverStartsAt: string;
  serverEndsAt: string;
  serverTimeRemainingPercentage: number;
}) {
  const [value, setValue] = useState(serverValue);
  const [isJoined, setIsJoined] = useState(serverIsJoined);
  const [joinableAt, setJoinableAt] = useState(serverJoinableAt);
  const [startsAt, setStartsAt] = useState(serverStartsAt);
  const [endsAt, setEndsAt] = useState(serverEndsAt);

  const [timeRemainingPercentage, setTimeRemainingPercentage] = useState(
    serverTimeRemainingPercentage,
  );

  useEffect(() => {
    const updateTimeRemaining = () => {
      const now = new Date();
      const start = new Date(startsAt);
      const end = new Date(endsAt);

      if (now < start) {
        setTimeRemainingPercentage(100);
      } else if (now > end) {
        setTimeRemainingPercentage(0);
      } else {
        const totalDuration = end.getTime() - start.getTime();
        const elapsed = now.getTime() - start.getTime();
        const remaining = 100 - (elapsed / totalDuration) * 100;
        setTimeRemainingPercentage(remaining);
      }
    };

    updateTimeRemaining();
    const interval = setInterval(updateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [startsAt, endsAt]);

  const fetchLatestCoinDrop = useCallback(async () => {
    const createClient = await import('utils/supabase/client').then(
      (mod) => mod.createClient,
    );

    const supabase = createClient();

    const { data, error } = await supabase
      .from('coin_drops')
      .select('*,coin_drop_entries(*)')
      .eq('status', 'active')
      .filter(
        'coin_drop_entries.user_id',
        'eq',
        userId ?? 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa',
      )
      .returns<
        {
          coin_drop_entries: {
            user_id: string;
          }[];
          value: number;
          joinable_at: string;
          starts_at: string;
          ends_at: string;
        }[]
      >()
      .single();

    if (error !== null) {
      toast.error('Failed to fetch active coin drop');
      return;
    }

    setValue(data.value);

    setIsJoined(
      data.coin_drop_entries.some((entry) => entry.user_id === userId),
    );
    setJoinableAt(data.joinable_at);
    setStartsAt(data.starts_at);
    setEndsAt(data.ends_at);
  }, [userId]);

  const createCoinDropChannel = useCallback(
    async (supabase: SupabaseClient<Database>) => {
      return supabase.channel('realtime_coin_drop').on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'coin_drops',
        },
        (payload) => {
          if (payload.new.status === 'completed') {
            void fetchLatestCoinDrop();
          } else {
            setValue(payload.new.value);
          }
        },
      );
    },
    [fetchLatestCoinDrop],
  );

  useReconnectOnTabChange(createCoinDropChannel, fetchLatestCoinDrop);

  const isEnded = new Date(endsAt) < new Date();
  const isJoinable = new Date(joinableAt) < new Date() && !isEnded;
  const isLoggedIn = userId !== undefined;

  return (
    <div
      className={cn(
        `absolute left-1/2 top-0 z-10 w-[calc(100%-1rem)] overflow-hidden rounded-lg bg-secondary p-4 transition-all -translate-x-1/2 translate-y-0`,
        {
          '-translate-y-full': isEnded,
        },
      )}
    >
      <div className="mb-2 flex items-center gap-2">
        {isJoinable ? (
          <div>
            <Typography
              variant="p"
              className="grow text-sm font-semibold uppercase tracking-tight"
            >
              <span className="mr-1">Coin Drop Incoming</span>
              <CoinDropInfoPopover />
            </Typography>
            <Typography
              variant="p"
              className="text-xs font-normal tracking-tight text-secondary-foreground/80"
            >
              Join now to collect a share of the coins
            </Typography>
          </div>
        ) : (
          <Typography
            variant="p"
            className="grow text-sm font-semibold uppercase tracking-tight"
          >
            <span className="mr-1">Coin Drop</span>
            <CoinDropInfoPopover />
          </Typography>
        )}

        <div
          className={cn(
            'shrink-0 rounded-full py-0.5 pl-1 pr-1.5 transition-colors',
            {
              'bg-yellow-500/20': isJoinable,
              'bg-frost': !isJoinable,
            },
          )}
        >
          <Coins value={value} animated />
        </div>
      </div>
      {isJoinable && !isJoined ? (
        <JoinCoinDropForm
          isLoggedIn={isLoggedIn}
          hasVerifiedPhone={hasVerifiedPhone}
          onJoin={() => setIsJoined(true)}
        />
      ) : isJoined ? (
        <Typography
          variant="span"
          affects="xs"
          className="bg-frost flex w-fit items-center gap-1 rounded-full p-1 pr-3"
        >
          <CheckIcon className="size-5 text-success" /> Joined!
        </Typography>
      ) : null}
      <div className="mt-4">
        <Progress value={timeRemainingPercentage} className="w-full" />
      </div>

      <div
        className={`absolute -left-full top-0 -z-10 size-[200%] rounded-full blur-2xl transition-all duration-300 ${
          isJoinable ? 'opacity-100' : 'opacity-0'
        } ${isJoined ? 'bg-success/50' : 'bg-rich-gold'}`}
        style={{
          pointerEvents: 'none',
          zIndex: -10,
        }}
      />
    </div>
  );
}

function CoinDropInfoPopover() {
  return (
    <InfoTooltip className="bg-dialog">
      <div className="flex flex-col gap-2">
        <Typography variant="p" affects="default">
          What is Coin Drop?
        </Typography>
        <Divider variant="section" />
        <Typography variant="p" affects="small">
          Coin Drop is a unique, free-to-play rewards feature. During the last
          10 minutes of every 30 minute drop,{' '}
          <strong>users who have verified their phone number</strong> can click
          the Join button to receive a share of the coin pool.
        </Typography>
      </div>
    </InfoTooltip>
  );
}

function JoinCoinDropForm({
  isLoggedIn,
  hasVerifiedPhone,
  onJoin,
}: {
  isLoggedIn: boolean;
  hasVerifiedPhone: boolean;
  onJoin: () => void;
}) {
  const [formState, action] = useFormState(joinCoinDrop, EMPTY_FORM_STATE);
  const captcha = useRef<HCaptcha>(null);
  const [token, setToken] = useState<string>();
  const formRef = useRef<HTMLFormElement>(null);
  const { showAuthDialog } = useAuthDialog();

  const prevTimestamp = useRef(formState.timestamp);
  useFormToastMessage(formState);

  if (prevTimestamp.current !== formState.timestamp) {
    prevTimestamp.current = formState.timestamp;

    if (formState.status === 'SUCCESS') {
      onJoin();
    }

    captcha.current?.resetCaptcha();
  }

  useEffect(() => {
    if (token) {
      formRef.current?.requestSubmit();
    }
  }, [token]);

  return (
    <form action={action} ref={formRef}>
      {hasVerifiedPhone && isLoggedIn && (
        <CoinDropSubmitButton
          size="sm"
          onClick={(event) => {
            if (!token && env.NEXT_PUBLIC_CAPTCHA_ENABLED) {
              event.preventDefault();
              captcha.current?.execute();
            }
          }}
        />
      )}
      {!hasVerifiedPhone && isLoggedIn && (
        <Button asChild variant="action" size="sm" type="button">
          <Link href="/settings?tab=verification">Verify Phone to Join</Link>
        </Button>
      )}
      {!isLoggedIn && (
        <Button
          variant="frost"
          size="sm"
          onClick={() => showAuthDialog()}
          type="button"
        >
          Log In
        </Button>
      )}

      <input type="hidden" name="hcaptcha_token" value={token ?? ''} />

      {env.NEXT_PUBLIC_CAPTCHA_ENABLED && (
        <HCaptcha
          ref={captcha}
          sitekey={env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
          onVerify={setToken}
          onExpire={() => {
            captcha.current?.resetCaptcha();
          }}
          onChalExpired={() => {
            captcha.current?.resetCaptcha();
          }}
          theme="dark"
          loadAsync
          size="invisible"
        />
      )}
    </form>
  );
}

function CoinDropSubmitButton({ disabled, ...props }: ButtonProps) {
  const { pending } = useFormStatus();

  return (
    <Button
      variant="action"
      type="submit"
      disabled={pending || disabled}
      isProcessing={pending}
      {...props}
    >
      Join
    </Button>
  );
}
