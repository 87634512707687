'use client';

import { LEFT_SIDEBAR_WIDTH, useLayout } from './layout-context';

import { MessageCircleQuestion } from 'lucide-react';

export default function IntercomButton() {
  const { isLeftSidebarOpenable } = useLayout();

  return (
    <button
      className={`custom-intercom-launcher pointer-events-auto fixed bottom-16 z-20 flex h-10 items-center rounded-r-sm bg-secondary-foreground transition-transform duration-200 ease-out`}
      style={{
        transform: isLeftSidebarOpenable
          ? `translateX(${LEFT_SIDEBAR_WIDTH})`
          : `translateX(calc(${LEFT_SIDEBAR_WIDTH} - 2.5rem))`,
        transitionDuration: '200ms',
        transitionTimingFunction: 'ease-out',
      }}
    >
      <div className="flex h-full w-10 items-center justify-center bg-transparent">
        <MessageCircleQuestion className="size-6 text-secondary" />
      </div>
    </button>
  );
}
