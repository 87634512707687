'use client';

import AnimatedNumbers from 'components/animated-numbers';
import { Loader2 } from 'lucide-react';
import { useInventoryContext } from 'app/(main)/inventory-context';

export default function HeaderLiveInventoryCount() {
  const { count, isLoading } = useInventoryContext();

  return !isLoading ? (
    <AnimatedNumbers value={count} />
  ) : (
    <Loader2 className="size-4 animate-spin" />
  );
}
