export default function LightningIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m7.212 15.253 6.3-8.704H8.789V.747L2.487 9.451h4.725v5.802Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
