'use client';

import { createContext, useCallback, useContext, useState } from 'react';

import dynamic from 'next/dynamic';

const AuthDialog = dynamic(() => import('./auth-dialog'));

export const AuthDialogContext = createContext<{
  showAuthDialog: () => void;
}>({
  showAuthDialog: () => {},
});

export default function AuthDialogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const showAuthDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <AuthDialogContext.Provider
      value={{
        showAuthDialog,
      }}
    >
      <AuthDialog open={isOpen} onOpenChange={setIsOpen} />
      {children}
    </AuthDialogContext.Provider>
  );
}

export function useAuthDialog() {
  const context = useContext(AuthDialogContext);

  if (!context) {
    throw new Error('useAuthDialog must be used within an AuthDialogProvider');
  }

  return context;
}
