'use client';

import AnimatedNumbers from 'components/animated-numbers';
import type { Database } from 'schema.gen';
import SpinnerIcon from 'icons/spinner';
import type { SupabaseClient } from '@supabase/supabase-js';
import { minConfirmations } from 'lib/constants';
import { useCallback } from 'react';
import { useListState } from '@mantine/hooks';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';

type CryptoChillTransactionMetadata =
  Database['public']['Tables']['coins_purchased_cryptochill_transaction_metadata']['Row'];
export default function AwaitingCryptoConfirmationsToast({
  userId,
  initialPendingTransactions,
}: {
  userId: string;
  initialPendingTransactions: CryptoChillTransactionMetadata[];
}) {
  const [pendingTransactions, handlers] =
    useListState<CryptoChillTransactionMetadata>(initialPendingTransactions);

  const { setState, append, setItem, filter } = handlers;

  const fetchLatestTransactionMetadata = useCallback(async () => {
    const createClient = await import('utils/supabase/client').then(
      (mod) => mod.createClient,
    );

    const supabase = createClient();
    const { data, error } = await supabase
      .from('coins_purchased_cryptochill_transaction_metadata')
      .select('*')
      .eq('user_id', userId)
      .eq('cryptochill_transaction_status', 'transaction_pending');

    if (error !== null) {
      return;
    }

    setState(data);
  }, [userId, setState]);

  const createChannel = useCallback(
    async (supabase: SupabaseClient<Database>) => {
      return supabase
        .channel(`realtime_crypto_confirmations`)
        .on<CryptoChillTransactionMetadata>(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'coins_purchased_cryptochill_transaction_metadata',
            filter: `user_id=eq.${userId}`,
          },
          (payload) => {
            if (
              payload.eventType === 'INSERT' ||
              payload.eventType === 'UPDATE'
            ) {
              const transaction = payload.new;

              const index = pendingTransactions.findIndex(
                (t) => t.id === transaction.id,
              );

              if (
                transaction.cryptochill_transaction_status ===
                'transaction_pending'
              ) {
                if (index !== -1) {
                  setItem(index, transaction);
                } else {
                  append(transaction);
                }
              } else {
                filter((t) => t.id !== transaction.id);
              }
            }
          },
        );
    },
    [userId, pendingTransactions, setItem, append, filter],
  );

  useReconnectOnTabChange(createChannel, fetchLatestTransactionMetadata);

  if (pendingTransactions.length === 0) {
    return null;
  }

  return (
    <div className="flex animate-fade-up flex-col gap-2 rounded-t-lg bg-secondary p-4">
      {pendingTransactions.map((transaction) => (
        <div key={transaction.id} className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-xs font-normal">
            <SpinnerIcon className="animate-spin text-success" />{' '}
            <span className="flex items-center gap-1">
              Awaiting confirmations for {transaction.kind} transaction...{' '}
              <AnimatedNumbers value={transaction.num_confirmations} /> /{' '}
              {minConfirmations[transaction.kind]} received
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
