'use client';

import { Button } from 'components/ui/button';
import Image from 'next/image';
import discordIcon from 'public/discord.png';
import { env } from 'env';

export default function DiscordSignup() {
  return (
    <Button
      type="button"
      variant="frost"
      onClick={async () => {
        const createClient = await import('utils/supabase/client').then(
          (mod) => mod.createClient,
        );

        await createClient().auth.signInWithOAuth({
          provider: 'discord',
          options: {
            redirectTo: new URL(
              `/auth/callback?next=${encodeURIComponent(location.pathname)}`,
              env.NEXT_PUBLIC_WEB_APP_BASE_URL,
            ).toString(),
          },
        });
      }}
    >
      <div className="flex items-center justify-center gap-2">
        <span className="relative h-[1em] w-[1em]">
          <Image
            src={discordIcon}
            alt="Discord"
            fill
            className="object-contain"
            sizes="1em"
          />
        </span>
        Discord
      </div>
    </Button>
  );
}
