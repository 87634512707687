import { env } from 'env';
import { z } from 'zod';

export const loginSchema = z.object({
  email: z.string().trim().toLowerCase().email({
    message: 'Email is not valid.',
  }),
  password: z.string().trim().min(6),
  captchaToken: env.NEXT_PUBLIC_CAPTCHA_ENABLED
    ? z.string().min(1)
    : z.string().nullable().optional(),
});

export const signupSchema = z.object({
  username: z
    .string()
    .trim()
    .min(3, {
      message: 'Username must be at least 3 characters long.',
    })
    .max(24, {
      message: 'Username must be at most 24 characters long.',
    }),
  email: z.string().trim().toLowerCase().email({
    message: 'Email is not valid.',
  }),
  password: z.string().trim().min(6, {
    message: 'Password must be at least 6 characters long.',
  }),
  captchaToken: env.NEXT_PUBLIC_CAPTCHA_ENABLED
    ? z.string().min(1)
    : z.string().nullable().optional(),
});

export const forgotPasswordSchema = z.object({
  email: z.string().trim().toLowerCase().email('Invalid email address'),
  captchaToken: env.NEXT_PUBLIC_CAPTCHA_ENABLED
    ? z.string().min(1)
    : z.string().nullable().optional(),
});

export const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .trim()
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: z.string().trim(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });
