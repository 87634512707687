'use client';

import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';

import { Button } from 'components/ui/button';
import type { DialogProps } from '@radix-ui/react-dialog';
import { ExternalLinkIcon } from 'lucide-react';
import InfiniteInventoryGrid from 'app/(main)/inventory/infinite-inventory-grid';
import InventoryPageProvider from 'app/(main)/inventory/inventory-page-context';
import Link from 'next/link';
import OrderBy from 'app/(main)/inventory/order-by';
import SelectAll from 'app/(main)/inventory/select-all';
import SellButton from 'app/(main)/inventory/sell-button';

export default function QuickInventoryDialog({
  children,
  ...props
}: DialogProps) {
  return (
    <Dialog {...props}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-screen-lg">
        <InventoryPageProvider>
          <DialogTitle className="sr-only">Quick Inventory</DialogTitle>
          <DialogDescription className="sr-only hidden">
            View your quick inventory
          </DialogDescription>

          <DialogHeader>
            <DialogTitle>Quick Inventory</DialogTitle>
          </DialogHeader>

          <div className="flex items-center justify-between">
            <DialogClose asChild>
              <Button
                variant="link"
                size="sm"
                asChild
                onClick={() => props.onOpenChange?.(false)}
              >
                <Link href="/inventory">
                  View Full Inventory{' '}
                  <ExternalLinkIcon className="ml-1 size-4" />
                </Link>
              </Button>
            </DialogClose>

            <div className="ml-auto w-min">
              <SelectAll />
            </div>
          </div>

          <OrderBy />

          <DialogBody>
            <div className="grow">
              <InfiniteInventoryGrid />
            </div>
          </DialogBody>

          <DialogFooter>
            <SellButton />
          </DialogFooter>
        </InventoryPageProvider>
      </DialogContent>
    </Dialog>
  );
}
