import type { Database } from 'schema.gen';
import type { SupabaseClient } from '@supabase/supabase-js';

export default async function getUserBalance(
  supabase: SupabaseClient<Database>,
  userId: string,
) {
  const { data, error } = await supabase
    .from('transactions')
    .select('running_balance, sequence_number')
    .filter('user_id', 'eq', userId)
    .order('sequence_number', { ascending: false })
    .limit(1)
    .returns<{ running_balance: number; sequence_number: number }[]>()
    .maybeSingle();

  if (error !== null) {
    throw new Error(error.message);
  }

  if (data === null) {
    return 0;
  }

  return data.running_balance;
}
