'use client';

import {
  type ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useListState } from '@mantine/hooks';
import type { Database } from 'schema.gen';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';
import type { SupabaseClient } from '@supabase/supabase-js';

type ChatMessage = Database['public']['Tables']['chat_messages']['Row'];

export const ChatContext = createContext<{
  messages: ChatMessage[];
  onlineCount: number;
}>({
  messages: [],
  onlineCount: 0,
});

export default function ChatProvider({
  serverChatMessages,
  userId,
  children,
}: {
  serverChatMessages: ChatMessage[];
  userId?: string;
  children: ReactNode;
}) {
  const [onlineCount, setOnlineCount] = useState(0);
  const [messages, { prepend }] = useListState<ChatMessage>(serverChatMessages);
  const latestMessageId = messages[0]?.id;

  const createChannel = useCallback(
    async (supabase: SupabaseClient<Database>) => {
      const channel = supabase
        .channel('realtime_chat_messages')
        .on<ChatMessage>(
          'postgres_changes',
          {
            event: 'INSERT',
            schema: 'public',
            table: 'chat_messages',
          },
          (payload) => {
            prepend(payload.new);
          },
        );

      channel.on('presence', { event: 'sync' }, () => {
        const newState = channel.presenceState<{
          user: string;
          online_at: string;
        }>();

        const onlineCount = new Set(
          Object.values(newState).map((presence) => presence[0]?.user),
        ).size;

        setOnlineCount(onlineCount);
      });

      return channel;
    },
    [prepend],
  );

  const refreshChat = useCallback(async () => {
    const res = await fetch(`/chat?after=${latestMessageId}`);
    if (res.ok) {
      const messages = await res.json();
      prepend(...messages);
    }
  }, [latestMessageId, prepend]);

  const { status, channel } = useReconnectOnTabChange(
    createChannel,
    refreshChat,
  );

  useEffect(() => {
    if (userId) {
      void channel?.track({
        user: userId,
        online_at: new Date().toISOString(),
      });
    } else {
      void channel?.untrack();
    }
  }, [channel, status, userId]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        onlineCount,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
