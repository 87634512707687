'use client';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useCallback, useRef, useState } from 'react';

import { Button } from 'components/ui/button';
import { Check } from 'lucide-react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Input } from 'components/ui/input';
import Typography from 'components/ui/typography';
import { env } from 'env';
import { forgotPasswordSchema } from './validation';
import { sendPasswordResetEmail } from 'actions/auth';
import { toast } from 'components/ui/sonner';
import { useHookFormAction } from '@next-safe-action/adapter-react-hook-form/hooks';
import { zodResolver } from '@hookform/resolvers/zod';

export function ForgotPasswordForm({
  onClose,
}: {
  onClose?: (success: boolean) => void;
}) {
  const [sent, setSent] = useState(false);
  const { form, handleSubmitWithAction } = useHookFormAction(
    sendPasswordResetEmail,
    zodResolver(forgotPasswordSchema),
    {
      formProps: {
        mode: 'onSubmit',
        defaultValues: {
          email: '',
          captchaToken: '',
        },
      },
      actionProps: {
        onExecute: () => {
          resetCaptcha();
        },
        onError: ({ error }) => {
          if (error.serverError) {
            toast.error(error.serverError.message);
          }
        },
        onSuccess: () => {
          setSent(true);
        },
      },
    },
  );

  const captcha = useRef<HCaptcha>(null);
  const resetCaptcha = useCallback(() => {
    captcha.current?.resetCaptcha();
    form.setValue('captchaToken', '');
  }, [form]);

  return (
    <Form {...form}>
      <form onSubmit={handleSubmitWithAction} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} type="email" placeholder="Enter your email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {form.formState.errors.root && (
          <FormMessage>{form.formState.errors.root.message}</FormMessage>
        )}

        {env.NEXT_PUBLIC_CAPTCHA_ENABLED && (
          <FormField
            control={form.control}
            name="captchaToken"
            render={({ field }) => (
              <FormItem>
                <HCaptcha
                  ref={captcha}
                  sitekey={env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
                  onVerify={(token) => field.onChange(token)}
                  onExpire={resetCaptcha}
                  onChalExpired={resetCaptcha}
                  theme="dark"
                  loadAsync
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {sent && (
          <Typography variant="p" affects="xs" className="text-success">
            Password reset email sent. Please check your inbox.
          </Typography>
        )}

        <div className="flex justify-end space-x-2">
          {onClose && (
            <Button
              variant="ghost"
              onClick={() => onClose(false)}
              type="button"
              size="lg"
            >
              Cancel
            </Button>
          )}
          <Button
            type="submit"
            variant="action"
            size="lg"
            aria-disabled={sent}
            onClick={sent ? (e) => e.preventDefault() : undefined}
            disabled={form.formState.isSubmitting || !form.formState.isValid}
            isProcessing={form.formState.isSubmitting}
          >
            {sent ? (
              <span>
                <Check className="mr-1 inline size-4 text-success" /> Email Sent
              </span>
            ) : (
              'Send Reset Email'
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
}
