'use client';

import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import {
  Box,
  CircleArrowUp,
  HandCoins,
  LogOut,
  Settings,
  Swords,
  Truck,
} from 'lucide-react';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from 'components/ui/drawer';

import AuthDialog from 'components/auth/auth-dialog';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import ChestIcon from 'icons/chest';
import ClockIcon from 'icons/clock';
import GearIcon from 'icons/gear';
import HeaderUserHamburgerDailyRewards from './header-user-hamburger-daily-rewards';
import LightningIcon from 'icons/lightning';
import Link from 'next/link';
import MenuIcon from 'icons/menu';
import SignOut from 'components/forms/sign-out';
import Typography from 'components/ui/typography';
import { cn } from 'utils/shadcn';
import { useHeaderUserLevel } from './header-user-level-context';

export default function HeaderUserHamburgerMenu({
  user,
}: {
  user?: { avatar: string; username: string } | null;
}) {
  return (
    <Drawer disablePreventScroll={false}>
      <DrawerTrigger asChild>
        <Button variant="ghost" size="icon" className="hover:bg-transparent">
          <MenuIcon />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle className="sr-only">Navigation menu</DrawerTitle>
          <DrawerDescription className="sr-only">
            Navigation menu
          </DrawerDescription>
        </DrawerHeader>

        {user && <User avatar={user.avatar} username={user.username} />}
        {!user && <Login />}

        <SectionDivider />

        <div className="flex flex-col divide-y divide-gold/25">
          <HamburgerLink href="/" icon={<ChestIcon />}>
            Boxes
          </HamburgerLink>
          <HamburgerLink href="/battles" icon={<Swords size={16} />}>
            Battles
          </HamburgerLink>
          <HamburgerLink icon={<CircleArrowUp size={16} />}>
            <span className="text-muted-foreground">Upgrader</span>
            <Badge className="ml-auto" variant="secondary">
              Coming Soon!
            </Badge>
          </HamburgerLink>

          <HamburgerLink icon={<HandCoins size={16} />}>
            <span className="text-muted-foreground">Exchange</span>
            <Badge className="ml-auto" variant="secondary">
              Coming Soon!
            </Badge>
          </HamburgerLink>
        </div>

        {user && <LoggedInMenu />}
      </DrawerContent>
    </Drawer>
  );
}

function SectionDivider() {
  return <div className="my-3 h-1 w-full bg-gold/25" />;
}

function Login() {
  return (
    <div className="mx-auto px-4">
      <AuthDialog>
        <Button variant="action" size="lg">
          Log In
        </Button>
      </AuthDialog>
    </div>
  );
}

function User({ avatar, username }: { avatar: string; username: string }) {
  const { level } = useHeaderUserLevel();

  return (
    <div className="bg-frost mx-4 grid grid-cols-[auto,1fr,auto] items-center gap-2 rounded-sm p-4">
      <Avatar>
        <AvatarImage src={avatar} alt={username} width={32} height={32} />
        <AvatarFallback />
      </Avatar>

      <div className="flex flex-col gap-1">
        <Typography variant="span" affects="xs" className="font-bold">
          {username}
        </Typography>
        <Typography variant="span" affects="xs">
          Level {level}
        </Typography>
      </div>

      <div className="flex gap-2">
        <DrawerClose asChild>
          <HeaderUserHamburgerDailyRewards />
        </DrawerClose>

        <DrawerClose asChild>
          <Button variant="frost" size="icon" asChild>
            <Link href="/settings">
              <Settings size={16} />
            </Link>
          </Button>
        </DrawerClose>
      </div>
    </div>
  );
}

function LoggedInMenu() {
  return (
    <>
      <SectionDivider />

      <div className="grid grid-cols-2 gap-2 p-4 pt-0">
        <HamburgerLink
          href="/inventory"
          icon={<ChestIcon />}
          className="bg-frost rounded-lg p-2"
        >
          Inventory
        </HamburgerLink>
        <HamburgerLink
          href="/withdrawals"
          icon={<Truck size={16} />}
          className="bg-frost rounded-lg p-2"
        >
          Withdrawals
        </HamburgerLink>
        <HamburgerLink
          href="/affiliate"
          icon={<LightningIcon className="size-4" />}
          className="bg-frost rounded-lg p-2"
        >
          Affiliate
        </HamburgerLink>
        <HamburgerLink
          href="/box-history"
          icon={<Box size={16} />}
          className="bg-frost rounded-lg p-2"
        >
          Box History
        </HamburgerLink>
        <HamburgerLink
          href="/battle-history"
          icon={<ClockIcon className="size-4" />}
          className="bg-frost rounded-lg p-2"
        >
          Battle History
        </HamburgerLink>
        <HamburgerLink
          href="/settings"
          icon={<GearIcon className="size-4" />}
          className="bg-frost rounded-lg p-2"
        >
          Settings
        </HamburgerLink>
        <HamburgerLink
          icon={<LogOut className="text-destructive" size={16} />}
          className="bg-frost rounded-lg p-2"
        >
          <SignOut>
            <button type="submit">
              <span className="text-destructive">Sign Out</span>
            </button>
          </SignOut>
        </HamburgerLink>
      </div>
    </>
  );
}

function HamburgerLink({
  href,
  icon,
  children,
  className,
}: {
  href?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) {
  const content = (
    <div className={cn('px-4 py-2', className)}>
      <Typography
        variant="span"
        affects="default"
        className="flex items-center gap-2"
      >
        <div className="bg-frost flex-items-center justify-center rounded-sm bg-opacity-5 p-1.5">
          {icon}
        </div>
        {children}
      </Typography>
    </div>
  );

  return (
    <DrawerClose asChild>
      {href ? <Link href={href}>{content}</Link> : <div>{content}</div>}
    </DrawerClose>
  );
}
