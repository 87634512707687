export default function SpinnerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 2v2M14 8h-2M12.244 12.244 10.83 10.83M8 14v-2M3.756 12.244 5.17 10.83M2 8h2M3.756 3.756 5.17 5.17"
      />
    </svg>
  );
}
