'use client';

import Observe from './events/observe';
import type { TransactionCreatedEvent } from './events/definitions';
import formatCoins from 'utils/format/coins';
import { toast } from 'components/ui/sonner';

export default function ConfettiObserver() {
  const handleTransactionCreated = (event: TransactionCreatedEvent) => {
    if (event.transaction.type.startsWith('coins_purchased')) {
      toast.success(
        `Success! ${formatCoins(event.transaction.coins)} coins have been added to your balance.`,
      );
    }

    if (event.transaction.type === 'affiliate_referee_deposit_bonus') {
      toast.success(
        `Bonus! ${formatCoins(event.transaction.coins)} coins have been added to your balance for using an affiliate code.`,
      );
    }

    if (event.transaction.type === 'coin_drop') {
      toast.success(
        `You received ${formatCoins(event.transaction.coins)} coins from the coin drop.`,
      );
    }
  };

  return <Observe onTransactionCreated={handleTransactionCreated} />;
}
