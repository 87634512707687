'use client';

import { CoinflowPurchaseProtection } from '@coinflowlabs/react';
import { env } from 'env';

export default function WrappedCoinflowPurchaseProtection() {
  return (
    <CoinflowPurchaseProtection
      coinflowEnv={env.NEXT_PUBLIC_COINFLOW_ENV}
      merchantId={env.NEXT_PUBLIC_COINFLOW_MERCHANT_ID}
    />
  );
}
