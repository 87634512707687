import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';

import ArrowIcon from 'icons/arrow';
import BoxIcon from 'icons/box';
import ChestIcon from 'icons/chest';
import ClockIcon from 'icons/clock';
import GearIcon from 'icons/gear';
import Image from 'next/image';
import LightningIcon from 'icons/lightning';
import Link from 'next/link';
import SignOut from 'components/forms/sign-out';
import SignoutIcon from 'icons/signout';
import StreamerModeToggle from 'app/(main)/settings/streamer-mode-toggle';
import { Truck } from 'lucide-react';
import circleFrame from 'public/circle-frame.png';

export default function HeaderUserDropdownMenu({
  currentLevelProgress,
  username,
  level,
  avatar,
}: {
  currentLevelProgress: number;
  username: string;
  level: number;
  avatar: string;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <UserDropdownMenuTrigger
          currentLevelProgress={currentLevelProgress}
          displayName={username}
          level={level}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[300px] p-0">
        <DropdownMenuLabel className="bg-frost m-2 grid grid-cols-[auto,1fr,auto] items-center gap-2 rounded-md bg-opacity-5 px-1 py-2">
          <Avatar>
            <AvatarImage src={avatar} alt={username} width={32} height={32} />
            <AvatarFallback></AvatarFallback>
          </Avatar>

          <p className="truncate">{username}</p>

          <div className="border-l-2 border-l-gray-600 px-2 py-1">
            <Logout />
          </div>
        </DropdownMenuLabel>
        <div className="divide-y divide-gray-700 border-t border-white/10">
          <UserDropdownMenuItem
            href="/inventory"
            label="Inventory"
            icon={<ChestIcon />}
          />
          <UserDropdownMenuItem
            href="/withdrawals"
            label="Withdrawals"
            icon={<Truck className="size-4" />}
          />
          <UserDropdownMenuItem
            href="/affiliate"
            label="Affiliate"
            icon={<LightningIcon />}
          />
          <UserDropdownMenuItem
            href="/box-history"
            label="Box History"
            icon={<BoxIcon className="size-4" />}
          />
          <UserDropdownMenuItem
            href="/battle-history"
            label="Battle History"
            icon={<ClockIcon />}
          />
          <UserDropdownMenuItem
            href="/settings"
            label="Settings"
            icon={<GearIcon />}
          />
        </div>
        <div className="border-t border-white/10 px-2 py-3">
          <StreamerModeToggle />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function UserDropdownMenuTrigger({
  level,
  displayName,
  currentLevelProgress,
}: {
  level: number;
  displayName: string;
  currentLevelProgress: number;
}) {
  return (
    <div className="relative grid h-10 w-52 grid-cols-[auto,1fr,auto] items-center gap-2 overflow-hidden rounded-sm bg-stripes-muted px-3 py-1">
      <UserDropdownMenuLevel level={level} />

      <div className="z-10 mr-auto w-full truncate text-start text-xs">
        {displayName && <div className="truncate font-bold">{displayName}</div>}
        {level && <div className="font-normal text-gold">Level {level}</div>}
      </div>

      <ArrowIcon className="z-10 justify-self-end" />

      {currentLevelProgress !== undefined && (
        <div
          className="absolute right-0 top-0 z-0 h-full w-full origin-right bg-secondary transition-transform"
          style={{
            transform: `scaleX(${(1 - currentLevelProgress) * 100}%)`,
          }}
        ></div>
      )}
    </div>
  );
}

function UserDropdownMenuLevel({ level }: { level?: number }) {
  return (
    <div className="relative z-10 h-7 w-7">
      <Image
        src={circleFrame}
        alt="Circle Frame"
        fill
        sizes="28px"
        className="object-contain"
      />
      <span className="absolute left-1/2 top-1/2 text-center text-xs font-bold -translate-x-1/2 -translate-y-1/2">
        {level}
      </span>
    </div>
  );
}

function UserDropdownMenuItem({
  href,
  label,
  icon,
}: {
  href: string;
  label: string;
  icon: React.ReactNode;
}) {
  return (
    <DropdownMenuItem asChild className="rounded-none">
      <Link href={href}>
        <div className="bg-frost mr-2 rounded-sm bg-opacity-5 p-1.5">
          {icon}
        </div>
        {label}
      </Link>
    </DropdownMenuItem>
  );
}

function Logout() {
  return (
    <SignOut>
      <button className="flex">
        <SignoutIcon />
      </button>
    </SignOut>
  );
}
