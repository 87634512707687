import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';

import { Badge } from 'components/ui/badge';
import ClientFormattedDateTime from 'components/client-formatted-date-time';
import Typography from 'components/ui/typography';

export default function ChatMessage({
  message,
}: {
  message: {
    avatar: string;
    created_at: string;
    hash: string;
    id: number;
    is_deleted: boolean;
    is_mod: boolean;
    is_new: boolean;
    is_staff: boolean;
    text: string;
    user_id: string;
    user_level: number;
    username: string;
  };
}) {
  let color = '';
  let textColor = '#fff';

  if (message.user_level === 100) {
    color =
      'linear-gradient(to bottom, rgba(241, 143, 28, 1), rgba(247, 202, 15, 1))';
    textColor = '#111';
  } else if (message.user_level >= 80) {
    color = '#F7CA0F';
    textColor = '#000';
  } else if (message.user_level >= 60) {
    color = '#B723F2';
  } else if (message.user_level >= 40) {
    color = '#18B9FF';
  } else if (message.user_level >= 20) {
    color = '#2DC257';
  } else {
    color = '#6b7280';
  }

  return (
    <div key={message.hash} className="group grid grid-cols-[auto,1fr] gap-x-2">
      <Avatar className="size-8">
        <AvatarImage
          src={message.avatar}
          alt={message.username}
          width={32}
          height={32}
        />
        <AvatarFallback></AvatarFallback>
      </Avatar>

      <div className="relative flex flex-col gap-y-1">
        <span className="grid grid-cols-[1fr,auto,auto] items-center gap-x-1">
          <Typography
            variant="p"
            affects="small"
            className="min-w-0 flex-1 truncate"
          >
            <span>{message.username}</span>
          </Typography>

          <Badge
            variant="secondary"
            className="rounded-xs"
            style={{ background: color, color: textColor }}
          >
            {message.user_level}
          </Badge>

          {message.is_new && (
            <Typography
              variant="span"
              affects="xs"
              className="animate-wiggle-more animate-infinite"
              title="I'm new!"
            >
              👋🏻
            </Typography>
          )}
        </span>
        <Typography
          variant="p"
          affects="small"
          className="w-full max-w-full break-words font-normal text-muted-foreground"
          style={{
            overflowWrap: 'anywhere',
          }}
        >
          {message.text}
        </Typography>
        <Typography
          variant="span"
          affects="xs"
          className="absolute right-0 top-full text-2xs opacity-0 transition-all duration-300 translate-x-4 group-hover:opacity-100 group-hover:translate-x-0"
        >
          <ClientFormattedDateTime createdAt={new Date(message.created_at)} />
        </Typography>
      </div>
    </div>
  );
}
