import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/background-particles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/category-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/intercom-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/inventory-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/layout-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/left-sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/react-query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/right-sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(main)/settings/streamer-mode-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/auth/auth-dialog-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/auth/auth-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/chat-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/chat-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/online-count.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/realtime-chat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/chat/realtime-coin-drop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/coinflow/wrapped-coinflow-purchase-protection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EventProvider"] */ "/vercel/path0/components/events/context.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/header-v2/header-daily-rewards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header-v2/header-link-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header-v2/header-live-balance.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header-v2/header-live-inventory-count.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header-v2/header-live-user-dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/header-v2/header-logo.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/header-v2/header-user-hamburger-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/header-v2/header-user-level-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LiveHitsSkeleton","default"] */ "/vercel/path0/components/live-hits/realtime-live-hits.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/observers/confetti-observer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/observers/maintenance-observer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/payments/awaiting-coinflow-payments-toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/payments/awaiting-crypto-confirmations-toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/payments/deposit-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/quick-inventory-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/realtime/realtime-inventory-emitter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/realtime/realtime-maintenance-emitter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/realtime/realtime-transaction-emitter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/third-party/highlight/identify-user.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/third-party/intercom/wrapped-intercom-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/toast-observer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/components/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HighlightInit"] */ "/vercel/path0/node_modules/@highlight-run/next/dist/next-client.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"utils/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/gotham-pro/gothampro_light.ttf\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_lightitalic.ttf\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"../fonts/gotham-pro/gothampro.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../fonts/gotham-pro/gothampro_medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_mediumitalic.ttf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../fonts/gotham-pro/gothampro_bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_bolditalic.ttf\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../fonts/gotham-pro/gothampro_black.ttf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../fonts/gotham-pro/gothampro_blackitalic.ttf\",\"weight\":\"900\",\"style\":\"italic\"}],\"variable\":\"--font-gotham\"}],\"variableName\":\"fontGotham\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/circle-frame.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"utils/fonts.ts\",\"import\":\"Rubik\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
