'use client';

import { Button } from 'components/ui/button';
import { Gift } from 'lucide-react';
import Link from 'next/link';
import { useCategory } from 'app/(main)/category-context';

export default function HeaderDailyRewards() {
  const { category } = useCategory();

  return (
    <Button
      variant="success"
      size="lg"
      className="relative bg-stripes-success hover:bg-stripes-success"
      asChild
    >
      <Link href={`/daily-rewards/${category}`}>
        <span className="flex items-center gap-1">
          <Gift className="size-4" />
          <span className="hidden lg:block">Free Boxes</span>
        </span>
      </Link>
    </Button>
  );
}
