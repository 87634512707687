'use client';

import { Button } from 'components/ui/button';
import type { ButtonHTMLAttributes } from 'react';
import { Gift } from 'lucide-react';
import Link from 'next/link';
import { forwardRef } from 'react';
import { useCategory } from 'app/(main)/category-context';

const HeaderUserHamburgerDailyRewards = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  const { category } = useCategory();

  return (
    <Button
      variant="success"
      size="icon"
      className="relative bg-stripes-success hover:bg-stripes-success"
      asChild
      ref={ref}
      {...props}
    >
      <Link href={`/daily-rewards/${category}`}>
        <Gift className="size-4" />
      </Link>
    </Button>
  );
});

HeaderUserHamburgerDailyRewards.displayName = 'HeaderUserHamburgerDailyRewards';

export default HeaderUserHamburgerDailyRewards;
