import Typography from 'components/ui/typography';

export default function Or() {
  return (
    <div className="relative flex w-full items-center justify-center">
      <Typography variant="span" affects="xs" className="z-10 bg-dialog p-2">
        or
      </Typography>
      <div className="absolute top-1/2 z-0 h-[1px] w-full bg-white/10 -translate-y-1/2" />
    </div>
  );
}
