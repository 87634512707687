import type { Database } from 'schema.gen';

export const minConfirmations: Record<
  Database['public']['Enums']['supported_blockchains'],
  number
> = {
  BTC: 1,
  ETH: 3,
  LTC: 2,
};
