'use client';

import { Button } from 'components/ui/button';
import Image from 'next/image';
import twitchIcon from 'public/twitch.png';

export default function TwitchSignup() {
  return (
    <Button
      type="button"
      variant="frost"
      onClick={async () => {
        const { env } = await import('env');

        const createClient = await import('utils/supabase/client').then(
          (mod) => mod.createClient,
        );

        await createClient().auth.signInWithOAuth({
          provider: 'twitch',
          options: {
            redirectTo: new URL(
              `/auth/callback?next=${encodeURIComponent(location.pathname)}`,
              env.NEXT_PUBLIC_WEB_APP_BASE_URL,
            ).toString(),
          },
        });
      }}
    >
      <div className="flex items-center justify-center gap-2">
        <span className="relative h-[1em] w-[1em]">
          <Image
            src={twitchIcon}
            alt="Twitch"
            fill
            className="object-contain"
            sizes="1em"
          />
        </span>
        Twitch
      </div>
    </Button>
  );
}
