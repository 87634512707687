'use client';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { useCallback, useEffect, useState } from 'react';

import { Button } from 'components/ui/button';
import Image from 'next/image';
import Loading from 'icons/lordicon/loading.json';
import Typography from 'components/ui/typography';
import dynamic from 'next/dynamic';
import { env } from 'env';
import { loadStripe } from '@stripe/stripe-js';
import stripeLogo from 'public/stripe.svg';
import { toast } from 'components/ui/sonner';

const PlayOnce = dynamic(
  () => import('components/lord-icon-player').then((mod) => mod.PlayOnce),
  {
    ssr: false,
  },
);

const stripePromise = loadStripe(env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

export default function StripeCheckout({
  bundleId,
  onCheckoutSuccess,
}: {
  bundleId: string;
  onCheckoutSuccess?: () => void;
}) {
  const [isProcessing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [stripeCheckoutVisible, setStripeCheckoutVisible] = useState(false);

  const createCheckoutSession = useCallback(async () => {
    const createClient = await import('utils/supabase/client').then(
      (mod) => mod.createClient,
    );
    const supabase = createClient();
    const session = await supabase.auth.getSession();

    if (!session.data.session) {
      return;
    }

    setProcessing(true);
    const response = await fetch('/api/stripe/checkout-session', {
      method: 'POST',
      body: JSON.stringify({
        bundleId,
        userId: session.data.session.user.id,
      }),
    }).finally(() => setProcessing(false));

    if (response.status !== 200) {
      const { message } = await response.json();
      toast.error(message);
      return;
    }

    const data = await response.json();
    setClientSecret(data.clientSecret);
  }, [bundleId]);

  useEffect(() => {
    if (clientSecret) {
      setStripeCheckoutVisible(true);
    }
  }, [clientSecret]);

  const onComplete = useCallback(() => {
    setStripeCheckoutVisible(false);

    onCheckoutSuccess?.();
  }, [onCheckoutSuccess]);

  return (
    <Dialog
      open={stripeCheckoutVisible}
      onOpenChange={setStripeCheckoutVisible}
    >
      <DialogTrigger asChild>
        <Button onClick={createCheckoutSession} isProcessing={isProcessing}>
          <Image src={stripeLogo} alt="Stripe logo" width={64} />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Image
              src={stripeLogo}
              alt="Stripe logo"
              width={144}
              className="mx-auto"
            />
          </DialogTitle>
        </DialogHeader>
        <div id="checkout">
          {clientSecret ? (
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{
                clientSecret,
                onComplete,
              }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          ) : (
            <div className="bg-frost flex aspect-square w-full flex-col items-center justify-center gap-2 rounded-lg">
              <PlayOnce icon={Loading} loop />
              <Typography variant="span" className="animate-pulse">
                Connecting to Stripe...
              </Typography>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
