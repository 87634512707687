export default function IconLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 136.36 108.96"
      height={25}
      {...props}
    >
      <defs>
        <style>{'.cls-1{fill:#f5b812;stroke-width:0}'}</style>
      </defs>
      <path
        d="M38.23 0h56.48l41.65 53.42-56.49-.01L38.23 0z"
        className="cls-1"
      />
      <path
        d="M76.87 58.62 45.64 18.57H25.43l40.52 53.41h56.49l-7.71-13.4-37.86.04z"
        className="cls-1"
      />
      <path
        d="M63.01 77.11 32.62 37.06h-19.9l39.37 53.41h56.48l-7.7-13.4-37.86.04z"
        className="cls-1"
      />
      <path
        d="M49.14 95.6 19.62 55.55H0l38.23 53.41h56.48l-7.7-13.4-37.87.04z"
        className="cls-1"
      />
    </svg>
  );
}
