'use client';

import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { cn } from 'utils/shadcn';
import { useStreamerMode } from './streamer-mode-context';

export default function StreamerModeToggle() {
  const { isStreamerModeEnabled, setIsStreamerModeEnabled } = useStreamerMode();

  return (
    <div className="relative flex cursor-pointer items-center space-x-2">
      <Switch
        id="streamer-mode"
        type="submit"
        name="is_streamer_mode_enabled"
        checked={isStreamerModeEnabled}
      />
      <Label
        htmlFor="streamer-mode"
        className={cn('cursor-pointer font-normal transition-colors', {
          'text-white': isStreamerModeEnabled,
          'text-muted-foreground': !isStreamerModeEnabled,
        })}
      >
        Streamer Mode
      </Label>

      <div
        className="absolute left-0 h-full w-full cursor-pointer"
        onClick={() => setIsStreamerModeEnabled(!isStreamerModeEnabled)}
      />
    </div>
  );
}
