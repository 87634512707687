import React from 'react';

interface TikTokIconProps {
  className?: string;
}

const TikTokIcon: React.FC<TikTokIconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.5 5.25V7.75C14.5 7.88261 14.4473 8.00979 14.3536 8.10355C14.2598 8.19732 14.1326 8.25 14 8.25C12.9555 8.25274 11.926 8.0018 11 7.51875V9.75C11.0001 10.6537 10.7424 11.5386 10.2572 12.301C9.77205 13.0633 9.07945 13.6715 8.26075 14.054C7.44205 14.4365 6.53121 14.5776 5.63515 14.4606C4.73909 14.3436 3.89498 13.9735 3.20192 13.3936C2.50885 12.8137 1.99557 12.0482 1.72234 11.1868C1.4491 10.3254 1.42724 9.40401 1.65933 8.53066C1.89141 7.65731 2.36781 6.86828 3.0326 6.25618C3.69739 5.64409 4.52299 5.23433 5.4125 5.075C5.48387 5.06219 5.55717 5.06506 5.62732 5.08341C5.69747 5.10176 5.76279 5.13514 5.81875 5.18125C5.87643 5.22781 5.92274 5.28691 5.95415 5.35406C5.98556 5.4212 6.00124 5.49463 6 5.56875V8.16875C6.00027 8.26343 5.97331 8.35619 5.92233 8.43597C5.87136 8.51575 5.79852 8.5792 5.7125 8.61875C5.51072 8.71485 5.3384 8.8633 5.21348 9.04863C5.08856 9.23396 5.01562 9.44939 5.00224 9.67249C4.98887 9.89559 5.03556 10.1182 5.13744 10.3171C5.23933 10.516 5.39268 10.684 5.58154 10.8035C5.77039 10.9231 5.98784 10.9898 6.21123 10.9967C6.43462 11.0036 6.65579 10.9505 6.85169 10.8429C7.04759 10.7354 7.21107 10.5772 7.32509 10.385C7.43912 10.1928 7.49951 9.9735 7.5 9.75V1.75C7.5 1.61739 7.55268 1.49021 7.64645 1.39645C7.74021 1.30268 7.86739 1.25 8 1.25H10.5C10.6326 1.25 10.7598 1.30268 10.8536 1.39645C10.9473 1.49021 11 1.61739 11 1.75C11 2.54565 11.3161 3.30871 11.8787 3.87132C12.4413 4.43393 13.2044 4.75 14 4.75C14.1326 4.75 14.2598 4.80268 14.3536 4.89645C14.4473 4.99021 14.5 5.11739 14.5 5.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TikTokIcon;
