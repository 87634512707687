'use client';

import { ChatContext } from './chat-context';
import Typography from 'components/ui/typography';
import { useContext } from 'react';

export default function OnlineCount() {
  const { onlineCount } = useContext(ChatContext);
  return (
    <Typography variant="p" affects="xs">
      <span className="mr-2 inline-block size-2 animate-pulse rounded-full bg-success" />
      {onlineCount.toLocaleString()} online
    </Typography>
  );
}
