'use client';

import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useMediaQuery } from 'react-responsive';

export const LEFT_SIDEBAR_WIDTH = '14rem';
export const RIGHT_SIDEBAR_WIDTH = '17.5rem';

export const LayoutContext = createContext<{
  isLeftSidebarOpen: boolean;
  setLeftSidebarOpen: (isOpen: boolean) => void;
  isLeftSidebarOpenable: boolean;
  isRightSidebarOpen: boolean;
  setRightSidebarOpen: (isOpen: boolean) => void;
  isRightSidebarOpenable: boolean;
}>({
  isLeftSidebarOpen: false,
  setLeftSidebarOpen: () => {},
  isLeftSidebarOpenable: false,
  isRightSidebarOpen: false,
  setRightSidebarOpen: () => {},
  isRightSidebarOpenable: false,
});

export default function LayoutProvider({
  initialLeftOpen,
  initialRightOpen,
  children,
}: {
  initialLeftOpen?: boolean;
  initialRightOpen?: boolean;
  children: ReactNode;
}) {
  const [isLeftSidebarOpen, setLeftSidebarOpen] = useState(
    initialLeftOpen ?? false,
  );
  const [isRightSidebarOpen, setRightSidebarOpen] = useState(
    initialRightOpen ?? false,
  );
  const [previousIsLeftSidebarOpen, setPreviousIsLeftSidebarOpen] = useState(
    initialLeftOpen ?? false,
  );
  const [previousIsRightSidebarOpen, setPreviousIsRightSidebarOpen] = useState(
    initialRightOpen ?? false,
  );
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    if (previousIsLeftSidebarOpen !== isLeftSidebarOpen) {
      setPreviousIsLeftSidebarOpen(isLeftSidebarOpen);

      void fetch('/layout/left/toggle', {
        method: 'POST',
      });
    }
  }, [isLeftSidebarOpen, previousIsLeftSidebarOpen]);

  useEffect(() => {
    if (previousIsRightSidebarOpen !== isRightSidebarOpen) {
      setPreviousIsRightSidebarOpen(isRightSidebarOpen);

      void fetch('/layout/right/toggle', {
        method: 'POST',
      });
    }
  }, [isRightSidebarOpen, previousIsRightSidebarOpen]);

  useEffect(() => {
    if (isMobile) {
      if (isRightSidebarOpen) {
        setLeftSidebarOpen(false);
      } else if (isLeftSidebarOpen) {
        setRightSidebarOpen(false);
      }
    }
  }, [
    isLeftSidebarOpen,
    isRightSidebarOpen,
    isMobile,
    setLeftSidebarOpen,
    setRightSidebarOpen,
  ]);

  const isLeftSidebarOpenable = !isRightSidebarOpen || !isMobile;
  const isRightSidebarOpenable = !isLeftSidebarOpen || !isMobile;

  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        isLeftSidebarOpen,
        setLeftSidebarOpen,
        isRightSidebarOpen,
        setRightSidebarOpen,
        isLeftSidebarOpenable,
        isRightSidebarOpenable,
      }}
    >
      {children}
      {isBrowser &&
        createPortal(
          <div
            aria-hidden="true"
            className={`fixed inset-0 overflow-hidden bg-black/80 transition-opacity duration-300 md:hidden ${
              isLeftSidebarOpen || isRightSidebarOpen
                ? 'opacity-100'
                : 'pointer-events-none opacity-0'
            }`}
            onClick={(e) => {
              e.preventDefault();
              setLeftSidebarOpen(false);
              setRightSidebarOpen(false);
            }}
          />,
          document.body,
        )}
    </LayoutContext.Provider>
  );
}
export const useLayout = () => {
  return useContext(LayoutContext);
};
