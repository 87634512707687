'use client';

import { Button, type ButtonProps } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';

import { Input } from 'components/ui/input';
import { redeemAffiliateCode } from 'actions/affiliate-codes';
import { redeemAffiliateCodeSchema } from 'actions/affiliate-codes/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useHookFormAction } from '@next-safe-action/adapter-react-hook-form/hooks';
import { toast } from 'components/ui/sonner';

export default function AffiliateCodeForm({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const { form, handleSubmitWithAction } = useHookFormAction(
    redeemAffiliateCode,
    zodResolver(redeemAffiliateCodeSchema),
    {
      formProps: {
        mode: 'onSubmit',
        defaultValues: {
          code: '',
        },
      },
      actionProps: {
        onError: ({ error }) => {
          if (error.serverError) {
            toast.error(error.serverError.message);
          }
        },
        onSuccess,
      },
    },
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmitWithAction} className="w-full">
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormControl onChange={() => form.clearErrors('code')}>
                <Input
                  placeholder="Enter affiliate code"
                  right={
                    <SubmitButton
                      variant="action"
                      isProcessing={form.formState.isSubmitting}
                      disabled={form.formState.isSubmitting}
                    >
                      Apply
                    </SubmitButton>
                  }
                  className="w-full"
                  {...field}
                />
              </FormControl>
              <FormMessage />
              <FormDescription>
                Get <span className="text-gold">5%+ bonus coins</span> with an
                affiliate code!
              </FormDescription>
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}

function SubmitButton({ children, ...props }: ButtonProps) {
  return (
    <Button {...props} type="submit">
      {children}
    </Button>
  );
}
