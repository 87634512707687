'use client';

import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import type { BoxCategory } from './battles/types';

export const LEFT_SIDEBAR_WIDTH = '14rem';
export const RIGHT_SIDEBAR_WIDTH = '17.5rem';

export const CategoryContext = createContext<{
  category: BoxCategory;
  setCategory: (category: BoxCategory) => void;
}>({
  category: 'magic',
  setCategory: () => {},
});

export default function CategoryProvider({
  initialCategory,
  children,
}: {
  initialCategory: BoxCategory;
  children: ReactNode;
}) {
  const [category, setCategory] = useState(initialCategory);

  useEffect(() => {
    void fetch('/api/category', {
      method: 'POST',
      body: JSON.stringify({ category }),
    });
  }, [category]);

  return (
    <CategoryContext.Provider
      value={{
        category,
        setCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
}
export const useCategory = () => {
  return useContext(CategoryContext);
};
