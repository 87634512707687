import DoubleDiamondIcon from 'icons/double-diamond';

export default function Divider({
  variant = 'default',
}: { variant?: 'default' | 'section' } = {}) {
  switch (variant) {
    case 'default':
      return (
        <div className="grid w-full grid-cols-[1fr,auto,1fr] items-center justify-center gap-2">
          <div className="h-px w-full shrink bg-gold" />
          <DoubleDiamondIcon className="text-gold" />
          <div className="h-px w-full shrink bg-gold" />
        </div>
      );
    case 'section':
      return (
        <div className="grid w-full grid-cols-[auto,1fr,auto] items-center justify-center gap-2">
          <DoubleDiamondIcon className="text-gold" />
          <div className="h-px w-full shrink bg-gold" />
          <DoubleDiamondIcon className="text-gold" />
        </div>
      );
  }
}
