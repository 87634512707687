'use client';

import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { ChatContext } from './chat-context';
import ChatMessage from './chat-message';

export default function RealtimeChat() {
  const { messages } = useContext(ChatContext);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const scrollToBottom = useCallback((behavior: ScrollBehavior = 'smooth') => {
    if (scrollRef.current !== null) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior,
      });
    }
  }, []);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current !== null && isScrolledToBottom) {
      scrollToBottom();
    }
  }, [isScrolledToBottom, messages, scrollToBottom]);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLDivElement;
    const atBottom = target.scrollTop > -50;

    setIsScrolledToBottom(atBottom);
  }, []);

  return (
    <div
      className="no-scrollbar flex w-full max-w-full flex-col-reverse gap-y-5 overflow-x-hidden overflow-y-scroll border-b border-b-gray-500 p-2 pb-5"
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {messages.map((message) => (
        <ChatMessage key={message.id} message={message} />
      ))}
    </div>
  );
}
