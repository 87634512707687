/* eslint-disable @typescript-eslint/no-explicit-any */
import { env } from 'env';

declare global {
  interface Window {
    CryptoChill: {
      setup: (config: {
        account: string;
        profile: string;
        onOpen?: (data: any) => void;
        onUpdate?: (data: any) => void;
        onSuccess?: (data: any) => void;
        onIncomplete?: (data: any) => void;
        onCancel?: (data: any) => void;
      }) => void;
      open: (config: {
        amount: number;
        currency: string;
        onSuccess?: (data: any) => void;
        product: string;
        showPaymentURL: boolean;
        passthrough: string;
      }) => void;
      destroy: () => void;
    };
  }
}

export function onCryptoChillScriptLoad() {
  window.CryptoChill.setup({
    account: env.NEXT_PUBLIC_CRYPTOCHILL_ACCOUNT_ID,
    profile: env.NEXT_PUBLIC_CRYPTOCHILL_PROFILE_ID,
  });
}
