'use client';

import { createContext, useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import Observe from 'components/events/observe';
import { getCurrentLevelProgress } from 'utils/xp';
import { getLevelFromXp } from 'utils/xp';
import { useDebouncedCallback } from '@mantine/hooks';

const HeaderUserLevelContext = createContext<{
  xp: number;
  currentLevelProgress: number;
  level: number;
}>({
  xp: 0,
  currentLevelProgress: 0,
  level: 0,
});

export default function HeaderUserLevelProvider({
  initialXp,
  userId,
  children,
}: {
  initialXp: number;
  userId: string;
  children: React.ReactNode;
}) {
  const queryClient = useQueryClient();

  const { data: xp } = useQuery({
    queryKey: ['xp', userId],
    initialData: initialXp,
    staleTime: Infinity,
    queryFn: async () => {
      const createClient = await import('utils/supabase/client').then(
        (mod) => mod.createClient,
      );
      const supabase = createClient();

      const { data } = await supabase
        .from('user_levels')
        .select('xp')
        .eq('id', userId)
        .single();

      return data?.xp ?? 0;
    },
  });

  const onTransactionCreated = useDebouncedCallback(() => {
    void queryClient.invalidateQueries({ queryKey: ['xp', userId] });
  }, 1000);

  return (
    <HeaderUserLevelContext.Provider
      value={{
        xp,
        currentLevelProgress: getCurrentLevelProgress(xp),
        level: getLevelFromXp(xp),
      }}
    >
      {children}
      <Observe onTransactionCreated={onTransactionCreated} />
    </HeaderUserLevelContext.Provider>
  );
}

export const useHeaderUserLevel = () => {
  return useContext(HeaderUserLevelContext);
};
