import { Card, CardContent } from 'components/ui/card';

import { Button } from 'components/ui/button';
import Image from 'next/image';
import Typography from 'components/ui/typography';
import formatCoins from 'utils/format/coins';
import formatCurrency from 'utils/format/currency';
import { type Database } from 'schema.gen';

export default function CoinBundleGrid({
  onSelect,
  coinBundles,
  disabled,
}: {
  onSelect: (bundleId: string) => void;
  coinBundles: Database['public']['Tables']['coin_bundles']['Row'][];
  disabled?: boolean;
}) {
  return (
    <div className="grid grid-cols-2 gap-2 md:grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
      {coinBundles?.map((bundle) => (
        <CoinBundleCard
          key={bundle.id}
          bundleId={bundle.id}
          coinsWithoutBonus={bundle.coins_without_bonus}
          coins={bundle.coins}
          coinBonusPercentage={bundle.coin_bonus_percentage}
          image={bundle.image}
          cents={bundle.cost_in_cents}
          isBestValue={bundle.is_best_value}
          isPopular={bundle.is_popular}
          onClick={onSelect}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

function CoinBundleCard({
  bundleId,
  coinsWithoutBonus,
  coins,
  coinBonusPercentage,
  image,
  cents,
  isBestValue,
  isPopular,
  onClick,
  disabled,
}: {
  bundleId: string;
  coins: number;
  image: string;
  cents: number;
  onClick: (bundleId: string) => void;
  coinsWithoutBonus: number;
  coinBonusPercentage: string;
  isBestValue?: boolean;
  isPopular?: boolean;
  disabled?: boolean;
}) {
  return (
    <button
      onClick={() => onClick(bundleId)}
      disabled={disabled}
      className={disabled ? 'saturate-0' : undefined}
    >
      <Card className="bg-frost border-none">
        <CardContent className="group relative overflow-hidden border-none p-2 pt-6">
          {isPopular && (
            <div className="absolute left-1/2 top-1 z-10 rounded-full bg-orange-gradient px-3 py-0 text-xs font-semibold uppercase text-brown -translate-x-1/2">
              Popular
            </div>
          )}
          {isBestValue && (
            <div className="absolute left-1/2 top-1 z-10 text-nowrap rounded-full bg-teal-gradient px-3 py-0 text-xs font-semibold uppercase -translate-x-1/2">
              Best Value
            </div>
          )}
          <Typography
            variant="p"
            affects="xl"
            className="text-center text-lg font-extrabold leading-tight"
          >
            {formatCoins(coins)} COINS
          </Typography>
          {coinsWithoutBonus !== coins && (
            <div className="bg-frost absolute left-1/2 w-fit rounded-full px-4 py-1 opacity-50 -translate-x-1/2">
              <Typography
                variant="p"
                affects="default"
                className="text-nowrap text-center line-through"
              >
                {formatCoins(coinsWithoutBonus)} Coins
              </Typography>
            </div>
          )}
          <div className="relative flex aspect-square w-full items-center justify-center">
            <div className="absolute h-full w-full bg-orange-radial-gradient blur-3xl"></div>
            <div className="absolute h-full w-full transition-all duration-1000 group-hover:animate-pulse group-hover:scale-150">
              <Image
                src="/shine.png"
                alt="A shine"
                fill
                sizes="(max-width: 767px) 50vw, (max-width: 871px) 33vw, 250px"
                className="object-contain"
              />
            </div>
            <div className="relative aspect-square w-1/3 transition-transform duration-500 group-hover:scale-125">
              <Image
                src={image}
                alt="A coin bundle"
                className="object-contain"
                fill
                sizes="(max-width: 767px) 25vw, (max-width: 871px) 20vw, 75px"
                priority
              />
            </div>
          </div>
          {+coinBonusPercentage > 0 && (
            <div className="absolute left-1/2 -translate-x-1/2 -translate-y-full">
              <Typography
                variant="p"
                affects="default"
                className="text-orange-gradient mb-2 text-nowrap text-center font-medium"
              >
                {coinBonusPercentage}% BONUS Coins!
              </Typography>
            </div>
          )}
          <Button variant="action" className="w-full" asChild>
            <span>{formatCurrency(cents)}</span>
          </Button>
        </CardContent>
      </Card>
    </button>
  );
}
