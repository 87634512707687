'use client';

import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { useCallback, useState } from 'react';

import { Button } from 'components/ui/button';
import Picker from '@emoji-mart/react';
import SmileyIcon from 'icons/smiley';

export default function EmojiSelector({
  onEmojiSelect,
}: {
  onEmojiSelect: (emoji: string) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const fetchData = useCallback(async () => {
    const response = await fetch(
      'https://cdn.jsdelivr.net/npm/@emoji-mart/data',
    );

    return response.json();
  }, []);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="icon" className="size-6" type="button">
          <SmileyIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-fit bg-transparent p-0">
        <Picker
          data={fetchData}
          onEmojiSelect={(data: { native?: string; shortcodes: string }) => {
            onEmojiSelect(data.native ?? data.shortcodes);
            setIsOpen(false);
          }}
          theme="dark"
          previewPosition="none"
        />
      </PopoverContent>
    </Popover>
  );
}
