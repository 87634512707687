'use client';

import { ExternalLinkIcon, Swords } from 'lucide-react';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import Coins from 'components/coins';
import type { Database } from 'schema.gen';
import Image from 'next/image';
import Link from 'next/link';
import type { SupabaseClient } from '@supabase/supabase-js';
import Typography from 'components/ui/typography';
import { cn } from 'utils/shadcn';
import { useListState } from '@mantine/hooks';
import useReconnectOnTabChange from 'hooks/use-reconnect-on-tab-change';

type LiveHit = Database['public']['Tables']['live_hits']['Row'];

export default function RealtimeLiveHits({
  serverLiveHits,
}: {
  serverLiveHits: LiveHit[];
}) {
  const [liveHits, { prepend, setState }] = useListState(serverLiveHits);
  const [pendingLiveHits, { append, shift, setState: setPendingLiveHits }] =
    useListState<LiveHit>();
  const [isFetchingLatest, setIsFetchingLatest] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const firstLiveHitId = liveHits[0]?.id;
  const [prevInitialLiveHitId, setPrevInitialLiveHitId] = useState<
    string | undefined
  >(firstLiveHitId);

  useLayoutEffect(() => {
    if (containerRef.current && !isFetchingLatest) {
      if (firstLiveHitId !== prevInitialLiveHitId) {
        setPrevInitialLiveHitId(firstLiveHitId);
        setIsAnimating(true);

        void Promise.all([import('gsap'), import('gsap/Flip')]).then(
          ([{ gsap }, { Flip }]) => {
            gsap.registerPlugin(Flip);

            const container = containerRef.current;
            if (!container) return;

            const items = gsap.utils.toArray<HTMLElement>(container.children);
            const newItem = items[0];
            if (newItem === undefined) {
              return;
            }

            gsap.set(container, {
              y: -newItem.offsetHeight,
            });

            const state = Flip.getState(container);
            gsap
              .timeline()
              .add(
                Flip.from(state, {
                  ease: 'power1.inOut',
                  duration: 0.5,
                }),
              )
              .to(
                container,
                {
                  y: 0,
                  opacity: 1,
                  duration: 0.5,
                  ease: 'power1.inOut',
                  onComplete: () => {
                    setIsAnimating(false);
                  },
                },
                0,
              );
          },
        );
      }
    }
  }, [firstLiveHitId, prevInitialLiveHitId, isFetchingLatest]);

  useEffect(() => {
    if (pendingLiveHits.length > 0 && !isAnimating && !isFetchingLatest) {
      setIsAnimating(true);
      const [nextUp] = pendingLiveHits;
      shift();
      if (liveHits.length > 30) {
        setState(liveHits.slice(0, -1));
      }
      prepend(nextUp!);
    }
  }, [
    pendingLiveHits,
    isAnimating,
    shift,
    prepend,
    setState,
    liveHits,
    isFetchingLatest,
  ]);

  const createLiveHitsChannel = useCallback(
    async (supabase: SupabaseClient<Database>) => {
      return supabase.channel('realtime_live_hits').on<LiveHit>(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'live_hits',
        },
        (payload) => {
          if (pendingLiveHits.some((hit) => hit.id === payload.new.id)) {
            return;
          }

          append(payload.new);
        },
      );
    },
    [append, pendingLiveHits],
  );

  const fetchLatestLiveHits = useCallback(async () => {
    setIsFetchingLatest(true);

    const createClient = await import('utils/supabase/client').then(
      (mod) => mod.createClient,
    );
    const supabase = createClient();
    const { data } = await supabase
      .from('live_hits')
      .select('*')
      .order('created_at', { ascending: false })
      .limit(30);

    if (data) {
      setPendingLiveHits([]);
      setState(data);
    }
    setIsFetchingLatest(false);
  }, [setState, setPendingLiveHits]);

  useReconnectOnTabChange(createLiveHitsChannel, fetchLatestLiveHits);

  return (
    <div className="relative h-full overflow-y-hidden">
      <div ref={containerRef} className="absolute flex w-full flex-col">
        {liveHits.map((hit) => (
          <LiveHitCard key={hit.id} hit={hit} />
        ))}
      </div>
    </div>
  );
}

function LiveHitCard({ hit }: { hit: LiveHit }) {
  return (
    <Link href={hit.link_path} className="group relative overflow-hidden">
      <div
        className="relative bg-background px-1 py-4"
        style={{
          backgroundImage: `radial-gradient(150% 200% at 0% 0%, ${hit.color}66 0%, rgba(0, 0, 0, 0) 100%)`,
        }}
      >
        <div
          className={cn(
            'absolute bottom-0 right-0 aspect-square h-[125%] origin-center text-muted-foreground opacity-40 transition-all group-hover:opacity-100 group-hover:scale-105',
          )}
        >
          {hit.source === 'box' ? (
            <div className="relative size-full translate-x-1/4 translate-y-1/4 -rotate-12">
              <Image
                src={hit.link_image}
                width={128}
                height={128}
                alt={hit.link_name}
                className="object-contain"
                priority
              />
            </div>
          ) : (
            <Swords className="absolute bottom-0 right-0 size-[60%]" />
          )}
        </div>

        <div className="flex items-center gap-1 transition-all translate-x-0 group-hover:opacity-0 group-hover:-translate-x-4">
          <div className="relative z-10 size-16 flex-shrink-0">
            <Image
              src={hit.image}
              alt={hit.name}
              fill
              className="object-contain"
              sizes="(max-width: 768px) 20vw, 5vw"
            />
          </div>
          <div className="z-10 flex w-full flex-col gap-1 overflow-hidden">
            <Typography
              variant="p"
              affects="xs"
              className="truncate font-semibold text-primary"
            >
              {hit.name}
            </Typography>
            <Coins value={hit.value} affects="xs" />
            <Typography
              variant="p"
              affects="xs"
              className="text-muted-foreground"
            >
              {hit.username}
            </Typography>
          </div>
        </div>
        <div className="absolute left-2 top-1/2 z-10 flex w-1/2 flex-col items-center justify-center gap-2 opacity-0 transition-all -translate-y-1/2 translate-x-4 group-hover:opacity-100 group-hover:translate-x-0">
          <Typography variant="span" affects="small" className="text-center">
            {hit.link_name}
          </Typography>

          <Typography
            variant="span"
            affects="xs"
            className="flex items-center gap-1 font-semibold text-gold"
          >
            Go to {hit.source === 'box' ? 'Box' : 'Battle'}{' '}
            <ExternalLinkIcon className="size-4" />
          </Typography>
        </div>
      </div>
    </Link>
  );
}

export function LiveHitsSkeleton() {
  return (
    <div>
      {[...Array(15)].map((_, i) => (
        <div
          key={i}
          className="flex items-center gap-2 overflow-hidden p-2"
          style={{
            borderLeft: '4px solid #e2e8f0',
          }}
        >
          <div className="bg-frost relative z-10 h-12 w-12 flex-shrink-0 animate-pulse rounded-md"></div>
          <div className="z-10 flex flex-grow flex-col overflow-hidden">
            <div className="bg-frost mb-1 h-4 w-3/4 animate-pulse rounded"></div>
            <div className="bg-frost mb-1 h-3 w-1/2 animate-pulse rounded"></div>
            <div className="bg-frost h-3 w-1/3 animate-pulse rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );
}
