'use client';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { useCallback, useRef, useState } from 'react';

import { Button } from 'components/ui/button';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Input } from 'components/ui/input';
import type { PullboxError } from 'lib/errors';
import Typography from 'components/ui/typography';
import { env } from 'env';
import { login } from 'actions/auth';
import { loginSchema } from './validation';
import { useHookFormAction } from '@next-safe-action/adapter-react-hook-form/hooks';
import { zodResolver } from '@hookform/resolvers/zod';

export default function LoginForm({
  onForgotPasswordClick,
  onSuccess,
}: {
  onForgotPasswordClick: () => void;
  onSuccess: () => void;
}) {
  const [serverError, setServerError] = useState<{
    message: string;
    code: PullboxError['code'];
  } | null>(null);
  const { form, handleSubmitWithAction } = useHookFormAction(
    login,
    zodResolver(loginSchema),
    {
      formProps: {
        mode: 'onSubmit',
        defaultValues: {
          email: '',
          password: '',
        },
      },
      actionProps: {
        onExecute: () => {
          setServerError(null);
          resetCaptcha();
        },
        onError: ({ error }) => {
          if (error.serverError) {
            setServerError(error.serverError);
          }
        },
        onSuccess: async ({ data }) => {
          if (data?.user) {
            const createClient = await import('utils/supabase/client').then(
              (mod) => mod.createClient,
            );

            // causes realtime websocket connections to use the current session
            await createClient().auth.refreshSession();
            onSuccess();
          }
        },
      },
    },
  );

  const captcha = useRef<HCaptcha>(null);
  const resetCaptcha = useCallback(() => {
    captcha.current?.resetCaptcha();
    form.setValue('captchaToken', undefined);
  }, [form]);

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmitWithAction}
        className="flex flex-col space-y-4"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input type="password" placeholder="Password" {...field} />
              </FormControl>

              <button
                type="button"
                className="ml-auto p-0 text-xs font-normal leading-none text-gold"
                onClick={onForgotPasswordClick}
              >
                Forgot your password?
              </button>
              <FormMessage />
            </FormItem>
          )}
        />

        {env.NEXT_PUBLIC_CAPTCHA_ENABLED && (
          <FormField
            control={form.control}
            name="captchaToken"
            render={({ field }) => (
              <FormItem>
                <HCaptcha
                  ref={captcha}
                  sitekey={env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
                  onVerify={(token) => field.onChange(token)}
                  onExpire={resetCaptcha}
                  onChalExpired={resetCaptcha}
                  theme="dark"
                  loadAsync
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        {form.formState.errors.root && (
          <FormMessage>{form.formState.errors.root.message}</FormMessage>
        )}
        {serverError && serverError.code !== 'PASSWORD_RESET_REQUIRED' && (
          <FormMessage className="rounded-lg bg-destructive p-4 text-white">
            {serverError.message}
          </FormMessage>
        )}
        {serverError && serverError.code === 'PASSWORD_RESET_REQUIRED' && (
          <span className="bg-frost flex animate-jump-in items-center gap-4 rounded-lg border border-gold p-4">
            <Typography
              variant="span"
              className="shrink-0 animate-wiggle-more text-4xl text-white animate-infinite"
            >
              👋🏻
            </Typography>
            <Typography variant="p" className="animate-pulse text-gold">
              {serverError.message}
            </Typography>
          </span>
        )}

        <Button
          className="w-full"
          type="submit"
          variant="action"
          disabled={!form.formState.isValid}
          isProcessing={form.formState.isSubmitting}
          size="lg"
        >
          Sign in
        </Button>
      </form>
    </Form>
  );
}
