'use client';

import { Button, type ButtonProps } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { useCallback, useRef } from 'react';

import { EMPTY_FORM_STATE } from 'utils/forms';
import { Input } from 'components/ui/input';
import redeemGiftCode from 'actions/gift-codes';
import { redeemGiftCodeSchema } from 'actions/gift-codes/schema';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'components/ui/sonner';

export default function GiftCodeForm({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const form = useForm({
    resolver: zodResolver(redeemGiftCodeSchema),
    defaultValues: { code: '' },
  });

  const formRef = useRef<HTMLFormElement>(null);
  const onSubmit = useCallback(async () => {
    const formState = await redeemGiftCode(
      EMPTY_FORM_STATE,
      new FormData(formRef.current!),
    );

    if (formState.status === 'SUCCESS') {
      toast.success(formState.message);
      onSuccess?.();
    } else if (formState.status === 'ERROR') {
      form.setError('code', { type: 'manual', message: formState.message });
    }
  }, [form, onSuccess]);

  return (
    <Form {...form}>
      <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormControl onChange={() => form.clearErrors('code')}>
                <Input
                  placeholder="Have a gift code?"
                  right={
                    <SubmitButton
                      variant="secondary-action"
                      isProcessing={form.formState.isSubmitting}
                      disabled={form.formState.isSubmitting}
                    >
                      Redeem
                    </SubmitButton>
                  }
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}

function SubmitButton({ children, ...props }: ButtonProps) {
  return (
    <Button {...props} type="submit">
      {children}
    </Button>
  );
}
