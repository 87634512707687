'use client';

import HeaderUserDropdownMenu from './header-user-dropdown-menu';
import { useHeaderUserLevel } from './header-user-level-context';

export default function HeaderLiveUserDropdownMenu({
  username,
  avatar,
}: {
  username: string;
  avatar: string;
}) {
  const { currentLevelProgress, level } = useHeaderUserLevel();

  return (
    <HeaderUserDropdownMenu
      avatar={avatar}
      username={username}
      level={level}
      currentLevelProgress={currentLevelProgress}
    />
  );
}
