import React from 'react';

export default function ChestIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.824 2.312A2.224 2.224 0 0 0 1.6 4.536V8h4.094L6.4 5.6h3.2l.706 2.4h4.093v1.6H1.6v2.976c0 .614.499 1.112 1.113 1.112h10.575c.615 0 1.113-.498 1.113-1.112V4.536a2.224 2.224 0 0 0-2.225-2.224H3.824Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
