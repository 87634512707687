'use client';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';

import LoginForm from './login-form';
import SignupForm from './signup-form';
import { type DialogProps } from '@radix-ui/react-dialog';
import { useState } from 'react';
import { ForgotPasswordForm } from './forgot-password-form';
import Or from './or';
import OAuthButtons from './oauth-buttons';

export default function AuthDialog(props: DialogProps) {
  const [open, setOpen] = useState(props.open);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [currentTab, setCurrentTab] = useState<'login' | 'signup'>('login');

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      props.onOpenChange?.(false);
      setShowForgotPassword(false);
      setOpen(false);
    } else {
      props.onOpenChange?.(true);
      setOpen(true);
    }
  };

  return (
    <Dialog
      {...props}
      open={props.open ?? open}
      onOpenChange={handleOpenChange}
    >
      {props.children && (
        <DialogTrigger asChild>{props.children}</DialogTrigger>
      )}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {currentTab === 'login'
              ? showForgotPassword
                ? 'Reset your password'
                : 'Sign In'
              : 'Sign Up'}
          </DialogTitle>
        </DialogHeader>

        <div className="flex w-full flex-col items-center">
          <Tabs
            value={currentTab}
            onValueChange={(value) =>
              setCurrentTab(value as 'login' | 'signup')
            }
            className="w-full"
          >
            <div className="mb-8 flex justify-center">
              <TabsList className="h-12">
                <TabsTrigger value="login" className="h-full">
                  Sign in
                </TabsTrigger>
                <TabsTrigger value="signup" className="h-full">
                  Sign up
                </TabsTrigger>
              </TabsList>
            </div>
            <TabsContent value="login">
              {showForgotPassword ? (
                <ForgotPasswordForm
                  onClose={(success) => {
                    setShowForgotPassword(false);
                    if (success) {
                      handleOpenChange(false);
                    }
                  }}
                />
              ) : (
                <LoginForm
                  onForgotPasswordClick={() => setShowForgotPassword(true)}
                  onSuccess={() => {
                    handleOpenChange(false);
                  }}
                />
              )}
            </TabsContent>
            <TabsContent value="signup">
              <SignupForm
                onSuccess={() => {
                  handleOpenChange(false);
                }}
              />
            </TabsContent>
            <div className="mt-4 flex flex-col items-center gap-4">
              <Or />

              <OAuthButtons />
            </div>
          </Tabs>
        </div>
        <DialogDescription className="sr-only">
          This dialog allows you to log in or sign up.
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
