import React from 'react';

interface InstagramIconProps {
  className?: string;
}

const InstagramIcon: React.FC<InstagramIconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        fill="currentColor"
      />
      <path
        d="M10.75 1.75H5.25C4.32174 1.75 3.4315 2.11875 2.77513 2.77513C2.11875 3.4315 1.75 4.32174 1.75 5.25V10.75C1.75 11.6783 2.11875 12.5685 2.77513 13.2249C3.4315 13.8813 4.32174 14.25 5.25 14.25H10.75C11.6783 14.25 12.5685 13.8813 13.2249 13.2249C13.8813 12.5685 14.25 11.6783 14.25 10.75V5.25C14.25 4.32174 13.8813 3.4315 13.2249 2.77513C12.5685 2.11875 11.6783 1.75 10.75 1.75ZM8 11C7.40666 11 6.82664 10.8241 6.33329 10.4944C5.83994 10.1648 5.45542 9.69623 5.22836 9.14805C5.0013 8.59987 4.94189 7.99667 5.05764 7.41473C5.1734 6.83279 5.45912 6.29824 5.87868 5.87868C6.29824 5.45912 6.83279 5.1734 7.41473 5.05764C7.99667 4.94189 8.59987 5.0013 9.14805 5.22836C9.69623 5.45542 10.1648 5.83994 10.4944 6.33329C10.8241 6.82664 11 7.40666 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55871 10.6839 8.79565 11 8 11ZM11.25 5.5C11.1017 5.5 10.9567 5.45601 10.8333 5.3736C10.71 5.29119 10.6139 5.17406 10.5571 5.03701C10.5003 4.89997 10.4855 4.74917 10.5144 4.60368C10.5434 4.4582 10.6148 4.32456 10.7197 4.21967C10.8246 4.11478 10.9582 4.04335 11.1037 4.01441C11.2492 3.98547 11.4 4.00032 11.537 4.05709C11.6741 4.11386 11.7912 4.20999 11.8736 4.33332C11.956 4.45666 12 4.60166 12 4.75C12 4.94891 11.921 5.13968 11.7803 5.28033C11.6397 5.42098 11.4489 5.5 11.25 5.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InstagramIcon;
