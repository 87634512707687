import * as React from 'react';

export default function FullLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 802.74 111.6"
      height={25}
      {...props}
    >
      <defs>
        <style>
          {'.cls-1,.cls-2{fill:#f5b812;stroke-width:0}.cls-2{fill:#fff}'}
        </style>
      </defs>
      <path
        d="M0 0h61.63c9.47 0 17.72 1.41 24.75 4.23s12.21 6.94 15.57 12.37c3.35 5.43 5.03 12.13 5.03 20.12 0 12.03-3.06 21.29-9.18 27.78-6.12 6.49-18.07 9.74-35.84 9.74H28.58v34.33H0V0Zm60.19 51.57c7.56 0 12.32-1.36 14.29-4.07s2.95-6.31 2.95-10.78-1.17-7.82-3.51-10.38c-2.34-2.55-6.65-3.83-12.93-3.83H28.58v29.06h31.61ZM222.15 89.97c-3.46 6.76-9.02 12.05-16.68 15.89-7.66 3.83-18.95 5.75-33.85 5.75s-26.42-2.05-34.25-6.15c-7.82-4.1-13.38-9.39-16.68-15.89-3.3-6.49-4.95-15.59-4.95-27.3V0h28.74v62.27c0 9.05 1.78 15.65 5.35 19.8 3.57 4.15 10.83 6.23 21.79 6.23s18.87-2.1 22.11-6.31c3.25-4.2 4.87-10.78 4.87-19.72V0h28.74v62.27c0 11.71-1.73 20.94-5.19 27.7ZM239.29 0h28.74v85.26h70.41v23.31h-99.15V0ZM348.11 0h28.74v85.26h70.41v23.31h-99.15V0ZM456.93 0h61.47c12.99 0 23.18 1.92 30.57 5.75 7.4 3.83 11.1 10.64 11.1 20.44 0 9.05-2.1 15.57-6.31 19.56-4.2 3.99-9.82 5.99-16.84 5.99v1.28c11.5.32 19.29 2.26 23.39 5.83 4.1 3.57 6.15 9.07 6.15 16.52 0 10.64-3.65 18.84-10.94 24.59-7.29 5.75-17.86 8.62-31.69 8.62h-66.9V0Zm60.2 43.27c5.11 0 8.62-.88 10.54-2.63 1.92-1.76 2.87-4.28 2.87-7.58s-1.06-6.01-3.19-7.82c-2.13-1.81-5.32-2.72-9.58-2.72h-32.25v20.76h31.61Zm5.9 42.79c4.68 0 8.17-1.12 10.46-3.35 2.29-2.23 3.43-5.11 3.43-8.62 0-3.3-1.06-5.88-3.19-7.74-2.13-1.86-5.8-2.79-11.02-2.79h-37.2v22.51h37.52ZM746.22 73.76h-1.12l-22.67 34.81h-33.21l36.56-56.2L689.22 0h34.17l21.55 30.17h1.28L768.41 0h34.33L765.7 51.89l37.04 56.68h-33.37l-23.15-34.81Z"
        className="cls-2"
      />
      <path
        d="M609.69 0h56.49l41.64 53.42-56.49-.01L609.69 0z"
        className="cls-1"
      />
      <path
        d="m648.33 58.62-31.22-40.05h-20.22l40.53 53.41h56.48l-7.7-13.4-37.87.04z"
        className="cls-1"
      />
      <path
        d="m634.47 77.11-30.39-40.05h-19.9l39.37 53.41h56.49l-7.71-13.4-37.86.04z"
        className="cls-1"
      />
      <path
        d="m620.61 95.6-29.53-40.05h-19.62l38.23 53.41h56.49l-7.71-13.4-37.86.04z"
        className="cls-1"
      />
    </svg>
  );
}
