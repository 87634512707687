'use client';

import { CircleArrowUp, HandCoins, MoreHorizontal, Swords } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Button } from 'components/ui/button';
import ChestIcon from 'icons/chest';
import Link from 'next/link';
import type { LucideIcon } from 'lucide-react';
import Typography from 'components/ui/typography';
import { cn } from 'utils/shadcn';
import { forwardRef } from 'react';
import { useCategory } from 'app/(main)/category-context';
import { usePathname } from 'next/navigation';

type MenuItem = {
  href: string;
  icon: LucideIcon | React.ComponentType;
  label: string;
  id: string;
  hidden: boolean;
  comingSoon?: boolean;
};

export default function HeaderLinkMenu() {
  const { category } = useCategory();

  const pathname = usePathname();

  const MENU_ITEMS = useMemo<MenuItem[]>(
    () => [
      {
        href: `/${category}`,
        icon: ChestIcon,
        label: 'Boxes',
        id: 'boxes',
        hidden: false,
      },
      {
        href: '/battles',
        icon: Swords,
        label: 'Battles',
        id: 'battles',
        hidden: false,
      },
      {
        href: '#',
        icon: CircleArrowUp,
        label: 'Upgrade',
        id: 'upgrade',
        comingSoon: true,
        hidden: false,
      },
      {
        href: '#',
        icon: HandCoins,
        label: 'Exchange',
        id: 'exchange',
        comingSoon: true,
        hidden: false,
      },
    ],
    [category],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const [visibleItems, setVisibleItems] = useState(MENU_ITEMS);
  const [dropdownItems, setDropdownItems] = useState<MenuItem[]>([]);
  const observerRef = useRef<ResizeObserver | null>(null);
  const debounceTimerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const calculateVisibleItems = () => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      let currentWidth = 0;
      const visibleIndexes: number[] = [0];

      itemsRef.current.slice(1).forEach((item, index) => {
        if (!item) return;
        if (index === 0) {
          currentWidth += itemsRef.current[0]?.offsetWidth ?? 0;
        }
        currentWidth += item.offsetWidth;

        if (currentWidth + 60 <= containerWidth) {
          visibleIndexes.push(index + 1);
        }
      });

      const dropdown = MENU_ITEMS.filter((_, i) => !visibleIndexes.includes(i));

      setVisibleItems(
        MENU_ITEMS.map((item, i) => ({
          ...item,
          hidden: !visibleIndexes.includes(i),
        })),
      );
      setDropdownItems(dropdown);
    };

    const debouncedCalculate = () => {
      // Clear any existing timer
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }

      // Set new timer
      debounceTimerRef.current = setTimeout(calculateVisibleItems, 100);
    };

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    // Create a ResizeObserver with the debounced calculation
    observerRef.current = new ResizeObserver(debouncedCalculate);
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    if (containerRef.current) {
      observerRef.current.observe(containerRef.current);

      const parent = containerRef.current.parentElement;
      if (parent) {
        observerRef.current.observe(parent);
      }

      // Initial calculation (no need to debounce this one)
      calculateVisibleItems();
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [MENU_ITEMS]);

  return (
    <div ref={containerRef} className="flex w-full items-center gap-1">
      {visibleItems.map((item, index) => (
        <HeaderLinkMenuItem
          key={item.id}
          {...item}
          isActive={pathname === item.href}
          ref={(el) => {
            if (el) itemsRef.current[index] = el;
          }}
        />
      ))}

      {dropdownItems.length > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="px-2">
              <MoreHorizontal className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {dropdownItems.map((item) => (
              <DropdownMenuItem key={item.id}>
                <Link href={item.href} className="flex items-center gap-2">
                  <item.icon className="mb-px size-4" />
                  <span>{item.label}</span>
                </Link>
                {item.comingSoon && (
                  <span className="ml-2 text-xs italic text-success">
                    Coming soon!
                  </span>
                )}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
}

interface MenuItemProps {
  id: string;
  href: string;
  icon: LucideIcon | React.ComponentType;
  label: string;
  hidden?: boolean;
  comingSoon?: boolean;
  isActive?: boolean;
}

const HeaderLinkMenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  ({ id, href, icon: Icon, label, hidden, comingSoon, isActive }, ref) => {
    if (comingSoon) {
      return (
        <div
          key={id}
          className={cn(
            'group relative cursor-pointer',
            hidden && 'absolute -left-[1000%] -top-[1000%]',
          )}
        >
          <Button
            ref={ref}
            variant="ghost"
            size="lg"
            disabled
            className="px-2 text-muted-foreground opacity-100 transition-opacity group-hover:!opacity-20 hover:text-foreground"
          >
            <div className="flex items-center gap-2">
              <Icon className="mb-px size-4" />
              <Typography variant="span">{label}</Typography>
            </div>
          </Button>
          <span className="absolute left-1/2 top-1/2 w-full text-center text-sm font-semibold italic text-success opacity-0 transition-all -translate-x-1/2 -translate-y-1/2 rotate-0 scale-90 group-hover:opacity-100 group-hover:scale-100">
            Coming soon!
          </span>
        </div>
      );
    }

    return (
      <Button
        key={id}
        ref={ref}
        variant="ghost"
        size="lg"
        className={cn(
          'px-2 text-muted-foreground transition-colors hover:text-foreground',
          isActive && 'text-gold hover:text-gold',
          hidden && 'absolute -left-[1000%] -top-[1000%]',
        )}
      >
        <Link href={href} className="flex items-center gap-2">
          <Icon className="mb-px size-4" />
          <Typography variant="span">{label}</Typography>
        </Link>
      </Button>
    );
  },
);

HeaderLinkMenuItem.displayName = 'MenuItem';
