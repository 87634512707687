import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { ShuffleIcon } from '@radix-ui/react-icons';
import { forwardRef } from 'react';
import { generateUsername } from 'friendly-username-generator';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onRandomize: (username: string) => void;
}

const UsernameInput = forwardRef<HTMLInputElement, InputProps>(
  ({ onRandomize, ...props }, ref) => {
    const onRandomizeClick = () => {
      const username = generateUsername({ useHyphen: false });
      onRandomize(username);
    };

    return (
      <Input
        {...props}
        ref={ref}
        placeholder="Username"
        right={
          <Button onClick={onRandomizeClick} type="button" variant="frost">
            <ShuffleIcon />
          </Button>
        }
      />
    );
  },
);

UsernameInput.displayName = 'UsernameInput';

export default UsernameInput;
