'use client';

import Observe from '../events/observe';
import type { TransactionCreatedEvent } from '../events/definitions';
import { shootConfettiFromBottomCorners } from 'lib/confetti';

export default function ConfettiObserver() {
  const handleTransactionCreated = (event: TransactionCreatedEvent) => {
    if (event.transaction.type.startsWith('coins_purchased')) {
      shootConfettiFromBottomCorners();
    }
  };

  return <Observe onTransactionCreated={handleTransactionCreated} />;
}
