'use client';

import { chatMessageSchema } from './schema';
import { sendChatMessage } from 'actions/chat';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { Button } from 'components/ui/button';
import { useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { type z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'components/ui/sonner';
import EmojiSelector from './emoji-selector';
import { Textarea } from 'components/ui/textarea';
import ArrowIcon from 'icons/arrow';
import DiscordIcon from 'icons/discord';
import Link from 'next/link';
import InstagramIcon from 'icons/instagram';
import FacebookIcon from 'icons/facebook';
import TikTokIcon from 'icons/tik-tok';

export default function ChatForm() {
  const form = useForm<z.infer<typeof chatMessageSchema>>({
    resolver: zodResolver(chatMessageSchema),
    defaultValues: {
      message: '',
    },
  });

  const formRef = useRef<HTMLFormElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const onSubmit = async (data: z.infer<typeof chatMessageSchema>) => {
    try {
      if (data.message.trim() === '') {
        return;
      }

      form.reset({ message: '' });
      const formData = new FormData();
      formData.append('message', data.message.trim());
      const formState = await sendChatMessage(formData);

      if (formState.status === 'ERROR') {
        toast.error(formState.message);
        form.reset({ message: data.message });
      }
    } catch (error) {
      toast.error('An error occurred sending your message. Please try again.');
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const adjustHeight = () => {
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, 3 * 24); // Assuming 24px line height
      textarea.style.height = `${newHeight}px`;
    };

    textarea.addEventListener('input', adjustHeight);
    return () => textarea.removeEventListener('input', adjustHeight);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      void form.handleSubmit(onSubmit)();
    }
  };

  return (
    <Form {...form}>
      <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="flex flex-col overflow-hidden rounded-lg bg-secondary p-2 shadow-sm">
                  <Textarea
                    {...field}
                    ref={(e) => {
                      field.ref(e);
                      textareaRef.current = e;
                    }}
                    placeholder="Type your message here..."
                    className="resize-none border-none text-xs focus-visible:border-none focus-visible:outline-none focus-visible:ring-0"
                    rows={1}
                    onKeyDown={handleKeyDown}
                  />

                  <div className="flex w-full flex-row items-center justify-end gap-1">
                    <div className="mr-auto flex gap-1">
                      <Button
                        variant="frost"
                        size="icon"
                        className="h-8 w-8"
                        asChild
                      >
                        <Link
                          href="https://discord.gg/UZ6NndQfua"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <DiscordIcon />
                        </Link>
                      </Button>
                      <Button
                        variant="frost"
                        size="icon"
                        className="h-8 w-8"
                        asChild
                      >
                        <Link
                          href="https://instagram.com/pullbox.gg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <InstagramIcon />
                        </Link>
                      </Button>
                      <Button
                        variant="frost"
                        size="icon"
                        className="h-8 w-8"
                        asChild
                      >
                        <Link
                          href="https://www.facebook.com/people/Pullboxgg/61553808437625/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookIcon />
                        </Link>
                      </Button>
                      <Button
                        variant="frost"
                        size="icon"
                        className="h-8 w-8"
                        asChild
                      >
                        <Link
                          href="https://www.tiktok.com/@pullbox.gg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TikTokIcon />
                        </Link>
                      </Button>
                    </div>

                    <EmojiSelector
                      onEmojiSelect={(emoji) => {
                        field.onChange(`${field.value}${emoji}`);
                        textareaRef.current?.focus();
                      }}
                    />
                    <Button
                      type="submit"
                      variant="secondary-action"
                      size="icon"
                      className="size-6"
                    >
                      <ArrowIcon className="rotate-180" />
                    </Button>
                  </div>
                </div>
              </FormControl>
              {field.value.length > 0 && (
                <FormMessage className="text-xs text-destructive" />
              )}
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}
