import * as React from 'react';

import { cn } from 'utils/shadcn';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      containerClassName,
      type,
      placeholder,
      left,
      right,
      disabled,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cn(
          'relative grid h-14 w-full grid-cols-[auto_1fr_auto] items-center gap-1 rounded-lg bg-white/[3%] px-2 transition-colors',
          {
            'border border-white/15 [&:focus-within]:border-white/30 [&:focus-within]:bg-white/5':
              !disabled,
            'border border-white/5': disabled,
          },
          containerClassName,
        )}
      >
        <div>{left}</div>
        <div className="relative">
          <input
            type={type}
            className={cn(
              'peer w-full border-none bg-transparent text-base ring-0 transition-all translate-y-0 placeholder:text-transparent focus:outline-none focus:ring-0 active:outline-none active:ring-0 disabled:text-white/60',
              'outline-none',
              {
                '[&:not(:placeholder-shown)]:text-sm [&:not(:placeholder-shown)]:translate-y-1/2':
                  placeholder !== undefined,
              },
              className,
            )}
            disabled={disabled}
            placeholder={placeholder}
            ref={ref}
            {...props}
          />

          {placeholder && (
            <span className="pointer-events-none absolute left-0 top-1/2 text-base text-white/60 transition-all -translate-y-1/2 peer-disabled:text-white/30 peer-[&:not(:placeholder-shown)]:text-sm peer-[&:not(:placeholder-shown)]:-translate-y-full">
              {placeholder}
            </span>
          )}
        </div>
        <div>{right}</div>
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
