import React from 'react';

interface FacebookIconProps {
  className?: string;
}

const FacebookIcon: React.FC<FacebookIconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16 8C16 3.58127 12.4187 0 8 0C3.58127 0 0 3.58127 0 8C0 11.9938 2.925 15.3031 6.75 15.9031V10.3125H4.71873V8H6.75V6.2375C6.75 4.2328 7.94377 3.125 9.77187 3.125C10.6469 3.125 11.5625 3.28125 11.5625 3.28125V5.25H10.5531C9.55937 5.25 9.25 5.8672 9.25 6.5V8H11.4687L11.1141 10.3125H9.25V15.9031C13.075 15.3031 16 11.9938 16 8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FacebookIcon;
