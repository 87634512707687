'use client';

import { useEffect } from 'react';

export default function IdentifyUser() {
  useEffect(() => {
    const identifyUser = async () => {
      const { H } = await import('@highlight-run/next/client');
      const { createClient } = await import('utils/supabase/client');
      const supabase = createClient();
      const { data } = await supabase.auth.getSession();

      if (data.session) {
        H.identify(data.session.user.id, {
          id: data.session.user.id,
          email: data.session.user.email!,
          username: data.session.user.user_metadata.username,
          avatar: data.session.user.user_metadata.avatar,
          signed_up: data.session.user.created_at,
        });
      }
    };

    void identifyUser();
  }, []);

  return null;
}
