'use client';

import {
  createContext,
  type Usable,
  use,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { debounce } from 'lodash';
import { toast } from 'components/ui/sonner';
import { updateStreamerMode } from 'actions/settings';
import { useRouter } from 'next/navigation';

const StreamerModeContext = createContext({
  isStreamerModeEnabled: false,
  setIsStreamerModeEnabled: (_isStreamerModeEnabled: boolean) => {},
});

export default function StreamerModeProvider({
  initialEnabled: initialEnabledUsable,
  children,
}: {
  initialEnabled: Usable<boolean>;
  children: React.ReactNode;
}) {
  const initialEnabled = use(initialEnabledUsable);
  const router = useRouter();
  const [isStreamerModeEnabled, setIsStreamerModeEnabled] =
    useState(initialEnabled);

  const previousIsStreamerModeEnabled = useRef(isStreamerModeEnabled);

  const debouncedUpdateStreamerMode = useRef(
    debounce(async (enabled: boolean) => {
      toast.loading(`${enabled ? 'Enabling' : 'Disabling'} streamer mode...`, {
        id: 'streamer-mode-loading',
      });
      setIsStreamerModeEnabled(enabled);
      try {
        await updateStreamerMode(enabled).then(() => router.refresh());
        toast.success(`Streamer mode ${enabled ? 'enabled' : 'disabled'}`);
      } catch (error) {
        toast.error('Failed to update streamer mode');
      } finally {
        toast.dismiss('streamer-mode-loading');
      }
    }, 500),
  ).current;

  useEffect(() => {
    if (previousIsStreamerModeEnabled.current !== isStreamerModeEnabled) {
      previousIsStreamerModeEnabled.current = isStreamerModeEnabled;

      void debouncedUpdateStreamerMode(isStreamerModeEnabled);
    }
  });

  return (
    <StreamerModeContext.Provider
      value={{ isStreamerModeEnabled, setIsStreamerModeEnabled }}
    >
      {children}
    </StreamerModeContext.Provider>
  );
}

export function useStreamerMode() {
  return useContext(StreamerModeContext);
}
