'use client';

import { Form } from 'components/ui/form';
import { signOut } from 'actions/auth';
import { toast } from 'components/ui/sonner';
import { useHookFormAction } from '@next-safe-action/adapter-react-hook-form/hooks';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export default function SignOut({ children }: { children: React.ReactNode }) {
  const { form, handleSubmitWithAction } = useHookFormAction(
    signOut,
    zodResolver(z.any()),
    {
      formProps: {
        mode: 'onSubmit',
      },
      actionProps: {
        onError: ({ error }) => {
          if (error.serverError) {
            toast.error(error.serverError.message);
          }
        },
        onSuccess: () => {
          toast.success('Signed out successfully!');
        },
      },
    },
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmitWithAction}>{children}</form>
    </Form>
  );
}
