'use client';

import { RIGHT_SIDEBAR_WIDTH, useLayout } from './layout-context';
import { type ReactNode } from 'react';
import { cn } from 'utils/shadcn';

export default function RightSidebar({
  children,
  iconOpen,
  iconClosed,
}: {
  children: ReactNode;
  iconOpen: ReactNode;
  iconClosed: ReactNode;
}) {
  const { isRightSidebarOpen, setRightSidebarOpen, isRightSidebarOpenable } =
    useLayout();

  return (
    <>
      <div
        className={cn(
          `pointer-events-auto absolute right-0 isolate z-10 flex h-full transition-transform duration-200 ease-out`,
          {
            'translate-x-full': !isRightSidebarOpen,
            'translate-x-0': isRightSidebarOpen,
          },
        )}
        style={{
          transitionDuration: '200ms',
          transitionTimingFunction: 'ease-out',
        }}
      >
        <section
          className="relative z-40 flex h-full flex-col overflow-hidden bg-primary-foreground"
          style={{ width: RIGHT_SIDEBAR_WIDTH }}
        >
          {children}
        </section>
      </div>

      {/* chat toggle button */}
      <button
        className={`pointer-events-auto fixed bottom-4 z-20 flex h-10 items-center rounded-bl-sm rounded-tl-sm bg-primary-foreground transition-transform duration-200 ease-out`}
        style={{
          right: 0,
          transform: isRightSidebarOpen
            ? `translateX(-${RIGHT_SIDEBAR_WIDTH})`
            : !isRightSidebarOpenable
              ? 'translateX(2.5rem)'
              : 'translateX(0)',
          transitionDuration: '200ms',
          transitionTimingFunction: 'ease-out',
        }}
        onClick={() => setRightSidebarOpen(!isRightSidebarOpen)}
      >
        <div className="flex h-full w-10 items-center justify-center bg-transparent">
          {isRightSidebarOpen ? iconOpen : iconClosed}
        </div>
      </button>
    </>
  );
}
