export default function SignoutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path stroke="#fff" strokeWidth={2} d="M11.258 7h5v10.5h-5" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m6 14.985 1.5 1.5 4.243-4.242L7.5 8 6 9.5l2.743 2.743L6 14.985Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
