import confetti from 'canvas-confetti';

export const stars = () => {
  const defaults = {
    spread: 360,
    ticks: 50,
    gravity: 0,
    decay: 0.94,
    startVelocity: 30,
    colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
  };

  function shoot() {
    confetti({
      ...defaults,
      particleCount: 40,
      scalar: 1.2,
      shapes: ['star'],
    })?.catch((error) => console.error(error));

    confetti({
      ...defaults,
      particleCount: 10,
      scalar: 0.75,
      shapes: ['circle'],
    })?.catch((error) => console.error(error));
  }

  setTimeout(shoot, 0);
  setTimeout(shoot, 100);
  setTimeout(shoot, 200);
};

export const shootConfettiFromBottomCorners = (
  shapes?: confetti.Shape[],
  scalar?: number,
) => {
  const isMobile = window.innerWidth < 768;

  (function frame() {
    if (isMobile) {
      const particleCount = 50;
      const spread = 45;
      const startVelocity = 45;

      confetti({
        particleCount,
        spread,
        startVelocity,
        angle: 70,
        origin: { x: 0, y: 1 },
        shapes,
        scalar,
      })?.catch((error) => error);
      confetti({
        particleCount,
        spread,
        startVelocity,
        angle: 110,
        origin: { x: 1, y: 1 },
        shapes,
        scalar,
      })?.catch((error) => error);
    } else {
      const particleCount = 50;
      const spread = 45;
      const startVelocity = 75;
      confetti({
        particleCount,
        spread,
        startVelocity,
        angle: 60,
        origin: { x: 0, y: 1 },
        shapes,
        scalar,
      })?.catch((error) => error);
      confetti({
        particleCount,
        spread,
        startVelocity,
        angle: 120,
        origin: { x: 1, y: 1 },
        shapes,
        scalar,
      })?.catch((error) => error);
    }
  })();
};

export const onBoxHit = async () => {
  for (let i = 0; i < 1; i++) {
    shootConfettiFromBottomCorners();
  }
};
