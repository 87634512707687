'use client';

import { formatDateTime } from 'utils/format/date-time';
import { useMounted } from '@mantine/hooks';

export default function ClientFormattedDateTime({
  createdAt,
}: {
  createdAt: Date;
}) {
  const mounted = useMounted();

  if (!mounted) return null;

  return <>{formatDateTime(createdAt)}</>;
}
